import React from "react";
import JumboSplash from "./Splash/JumboSplash";
import styled from "styled-components";
import Icons from "./Icons/Icons";
import { Container, Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const FeatureDisplayContainer = styled(Container)`
  padding: 4rem 0;
  color: #060606;

  h2 {
    margin-bottom: 3rem;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .feature-section {
    margin-bottom: 4rem;
    p {
      margin-bottom: 0.5rem;
      text-align: left;
      color: #555;
      text-indent: 1rem;
    }
  }

  .feature-icon {
    font-size: 3rem;
    color: #0e4a73;
    margin-bottom: 1rem;
  }

  .feature-card {
    border: none;
    background-color: #f9f9f9;
    text-align: center;
    height: 100%;
  }

  .feature-card .card-body {
    padding: 2rem 1rem;
  }

  .card-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }

  .card-text {
    font-size: 1rem;
    color: #555;
  }
`;

const LearnButton = styled(Button)`
  background-color: #e5e7eb;
  color: #0e4a73;
  border: none;
  padding: 12px 24px;
  font-weight: 600;
  transition: all 0.2s ease;
  margin-top: 2rem;
  justify-self: center;
  align-self: center;
  &:hover {
    background-color: #0e4a73;
    color: #e5e7eb;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 400px;
  margin: 1rem auto;
  display: block;

  @media (min-width: 768px) {
    float: ${props => props.float || "right"};
    margin: ${props => props.float === "left" ? "1rem 1rem 1rem 0" : "1rem 0 1rem 1rem"};
    max-height:400px;
    width: auto;
  }
`;

const About = () => {
  return (
    <>
      <JumboSplash />
      <FeatureDisplayContainer>
        <h2>Who did all this? And why?</h2>

        <StyledImage
          src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fbcpalmerheadshot.jpeg?alt=media&token=433eb33e-2b7d-4d99-9560-eb9d8662d872"
          alt="Brian Palmer"
        />

        <p>Me! I did this.</p>

        <p>
          I'm Brian Palmer, the creator of Scribi. Before I ever wrote a line of
          code, though, I was an author. I still am! As B.C. Palmer, Brian C.
          Palmer, Simon Strange, and a few other experimental pen names over the
          years. I've ghostwritten over a hundred books as well, in everything
          from romance to cozy mystery, to urban fantasy and post-apocalyptic
          thrillers. I got my start in romance, but my childhood dream was
          always to write fantasy. For me, romance was where I started because
          that's just the first story idea I had that I finished, and I enjoyed
          it enough that I kept going. Fantasy, though, was always my first
          love.
        </p>

        <p>
          In those first years of focusing on romance, I learned a lot about
          writing and about my process. I had it down to a science, and built
          myself a reliable map through my storytelling process that worked well
          every time. I refined it with each book, and after a while it was
          second nature. So when I started work on my first fantasy novel—an
          urban fantasy book in the Saint Moreno series called <em>New Shoes</em> and
          then, later, <em>Saint of Dead Gods</em> because <em>New Shoes</em> turned out not
          to be all that marketable a title for an urban fantasy—well, that
          process didn't work for me. The goals were different, the characters
          were complex in different ways, the scope of the story was so much
          bigger and broader. Which I loved! But I had to kind of relearn
          everything all over again.
        </p>

        <p>
          And then it came time to write the second book in the series. I
          thought I had set myself up well for it. The first book was full of
          plot threads, little seeds planted for later harvest, and I habitually
          introduced side characters with hints of deeper backstory. So I waited
          a couple of months, let the ideas marinate, and then I went back and
          re-read the first book a few times. I scribbled notes in the margins,
          I collected sticky notes as bookmarks reminding me to go back and
          address this or that, or to develop one storyline or another. There
          were so many details to track. And I found myself thinking that I
          should have been keeping a document somewhere that had all this in it.
          A proper story bible. But I didn't have one. I had a bunch of sticky
          notes and chaos.
        </p>

        <StyledImage
          src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2FIMG_2999.jpg?alt=media&token=d57e43cb-2987-4e8f-b855-27af76b606c6"
          alt="A book with too many sticky notes"
          float="left"
        />

        <p>
          That's where Scribi really started. I wanted a tool that could help my
          ADHD brain wrangle all of that story in a way that I could actually
          make use of the details, and still be allowed to zoom out and see the
          bigger picture. And I didn't want a document that I had to pick
          through to find things. I wanted something more organized, and
          searchable, and flexible. Now, I did go looking and I did find some
          options out there. But they all fell short, and they all amounted to
          tools I had to use alongside whatever I was writing in at that time.
          For me, that wasn't going to work. I needed to be able to be in my
          flow while still grabbing these little details and plot threads. I
          needed to make a new character and have somewhere to stuff them until
          I was ready to go back and find out what made them so interesting to
          me in the first place.
        </p>

        <p>
          I couldn't find that tool. So in a fit of inspiration, frustration,
          possibly a bit of madness, and with the full and eager support of my
          ADHD and autistic brain, I decided to build that tool myself. That was
          seven years ago. I worked my way through programming languages, and
          must have built parts of Scribi a dozen times in Java, C, C++, Python,
          and JavaScript. It's been a web application, a mobile app, a desktop
          app, back to web, back to desktop.
        </p>

        <p>
          I lost count of how many versions I worked on, but each time it got a
          little more refined, a little closer to my vision, a little better at
          doing what I wanted it to do. I was writing books all that time as
          well, and kept running up against moments where I thought, "If only I
          had Scribi finished, I could do <em>this</em>," or, "Well, that's
          going on the feature list." I did, at one point, enlist a developer to
          help me get Scribi made, but that's expensive so I did a crowdfunding
          campaign at the worst point in recent history, and it of course
          tanked. But that's probably for the best. Every time I tried to
          explain what it was I wanted to build, the developers I was speaking
          with didn't quite seem to understand.
        </p>

        <p>
          And that made sense. They were developers, not authors. They didn't
          know what I was trying to capture, not just in technical terms, but in
          the experience of using the software. There are things that we know as
          authors that no one else does, and without that knowledge, you can't
          build a tool like this that can do what Scribi does in a way that
          works. That became evident as I started the development journey on my
          own, and built the first few iterations. I was looking for a feeling,
          but couldn't quite pin it down myself until I finally had the first
          working version of it a couple of years ago. Yeah, it's been workable
          for a couple of years. I just needed to get it right. And the thing
          is, once I did, writing with it became such a joy. Not that I didn't
          enjoy writing before, but there was a lot to manage, a lot of moving
          parts of storytelling and writing and even marketing and publishing
          that had to be in mind the whole time. When I built something that
          alleviated some of that pressure, there was a reservoir of pure
          creative joy available that made me fall in love with writing all over
          again.
        </p>

        <p>
          And that's what I want for other authors using Scribi. I want authors
          to be eager and excited to sit down and work, and tell awesome
          stories, and I want them to get that big grin on their face when they
          get to see their story worlds on display and realize, "Oh man, I did
          something really cool, and readers are going to love what I do with
          this next." That's an incredible feeling, and only storytellers ever
          get it. We're the only people that get this sense of almost impish
          excitement. We're mischief makers. We emotionally blackmail readers,
          we hide secrets, we plant surprises and traps throughout the story,
          and then we hand it over with a big, devious smile on our faces
          because we <em>know</em> what we've done; we know that the reader is
          in for a crazy ride and we're so excited to make them laugh, or cry,
          or whatever, because of the tricks we played on them. Scribi lets me
          be a better trickster.
        </p>

        <p>
          It's been an incredible journey, filled with late nights, countless
          cups of coffee, and moments of sheer exhilaration when something
          clicked into place. I've written books using Scribi, testing and
          refining it to ensure it serves the storyteller, not the other way
          around. I've learned enough that I could honestly go get a nice, cushy
          job as a developer and probably make a lot more than I have ever made
          off my books! I'm full-stack at this point—front end, back end, and
          everything connecting them. But I won't, because I did all this so
          that I could enjoy writing books more. And I do.
        </p>

        <p>
          At its core, Scribi is a labor of love. A fusion of my passion for
          storytelling and my newfound love for coding. I hope that when you use
          Scribi, you feel that same spark of joy. That it becomes a companion
          on your own journey, helping you craft the stories that only you can
          tell. Because in the end, we're all tricksters at heart, sharing our
          secrets with the world one story at a time.
        </p>

        <div className="feature-section">
          <LearnButton href="/download">
            See for yourself <Icons.DownloadIcon />
          </LearnButton>
        </div>
      </FeatureDisplayContainer>
    </>
  );
};

export default About;