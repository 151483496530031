import React from "react";
import { Scribi } from "../utilities/api/store/store";
import Icons from "../Icons/Icons";
import { signOutOfScribi } from "../utilities/firestore/authentication";
import { ModalOverlay } from "../Scribi-Components/ScribiComponents";

const DesktopUserMenu = () => {
  const {
    setShowUserMenu,
    modalPosition,
    setModalPosition,
    settings,
    navigate,
  } = React.useContext(Scribi);

  const modalRef = React.useRef(null);

  //A useEffect that finds the rect and position of .work-header and adjusts the position of the .modal div accordingly:
  React.useEffect(() => {
    const headerRect = document
      .querySelector(".work-header")
      .getBoundingClientRect();
    const modalRect = modalRef.current.getBoundingClientRect();
    const modalPosition = {
      x: headerRect.right - modalRect.width - 5,
      y: headerRect.bottom,
    };
    setModalPosition(modalPosition);
  }, [settings, setModalPosition]);

  return (
    <ModalOverlay
      onClick={() => setShowUserMenu(false)}
      position={{ x: modalPosition.x, y: modalPosition.y }}
    >
      <div className="modal" ref={modalRef}>
        <UserMenuOptions />
      </div>
    </ModalOverlay>
  );
};

export default DesktopUserMenu;

const UserMenuOptions = () => {
  const { setShowUserMenu, navigate } = React.useContext(Scribi);
  return (
    <>
      <div
        alt="Your Account"
        className="menuOption"
        onClick={(e) => {
          e.stopPropagation();
          navigate("/account");
          setShowUserMenu(false);
        }}
      >
        <Icons.UserIcon />
        <label>Your Account</label>
      </div>
      <div
        alt="User Settings"
        className="menuOption"
        onClick={(e) => {
          e.stopPropagation();
          navigate("/settings");
          setShowUserMenu(false);
        }}
      >
        <Icons.SettingsIcon />
        <label>User Settings</label>
      </div>
      <div
        className="menuOption"
        alt="support"
        onClick={(e) => {
          e.stopPropagation();
          navigate("/support");
          setShowUserMenu(false);
        }}
      >
        <Icons.SupportIcon />
        <label>Support</label>
      </div>
      <div
        className="menuOption"
        alt="Sign Out"
        onClick={(e) => {
          e.stopPropagation();
          navigate("/");
          signOutOfScribi();
          setShowUserMenu(false);
        }}
      >
        <Icons.LogOutIcon />
        <label>Sign Out</label>
      </div>
    </>
  );
};
