import React, { useContext, useEffect } from "react";
import {
  ConfirmButton,
  HeaderLink,
  TextInput,
} from "./Scribi-Components/ScribiComponents";
import { Scribi } from "./utilities/api/store/store";
import styled from "styled-components";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import ScribiRestService from "./utilities/firestore/ScribiRestService";

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 100%;
  color: #555;
`;

const ErrorLabel = styled.label`
  color: ${(props) => (props.valid ? "green" : "red")};
  font-size: 0.8rem;
  text-align: center;
`;

const AuthPage = () => {
  const { setModal } = useContext(Scribi);
  const navigate = useNavigate();
  const [displayName, setDisplayName] = React.useState("");
  const [displayNameMessage, setDisplayNameMessage] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [emailMessage, setEmailMessage] = React.useState(null);
  const [password, setPassword] = React.useState("");
  const [passwordMessage, setPasswordMessage] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [confirmPasswordMessage, setConfirmPasswordMessage] =
    React.useState(null);
  const [loginState, setLoginState] = React.useState("login");
    const [unverifiedUser, setUnverifiedUser] = React.useState(null);

  const auth = getAuth();

  const validateSignup = () => {
    if (displayName.trim().length === 0) {
      setDisplayNameMessage("Display name cannot be empty.");
      return false;
    }
    if (email.trim().length === 0) {
      setEmailMessage("Email cannot be empty.");
      return false;
    }
    if (password.trim().length === 0) {
      setPasswordMessage("Password cannot be empty.");
      return false;
    }
    if (confirmPassword.trim().length === 0) {
      setConfirmPasswordMessage("Please confirm your password.");
      return false;
    }
    return true;
  };

  const validateLogin = () => {
    if (email.trim().length === 0) {
      setEmailMessage("Email cannot be empty.");
      return false;
    }
    if (password.trim().length === 0) {
      setPasswordMessage("Password cannot be empty.");
      return false;
    }
    return true;
  };

  const validateForgot = () => {
    if (email.trim().length === 0) {
      setEmailMessage("Email cannot be empty.");
      return false;
    }
    return true;
  };

  // Display Name Validation
  const handleDisplayNameChange = (e) => {
    const value = e.target.value;
    if (/^[\w]*$/.test(value)) {
      // Allows only letters, numbers, underscores
      setDisplayName(value);
      setDisplayNameMessage(null);
    } else {
      setDisplayNameMessage(
        "Only letters, numbers, and underscores are allowed."
      );
    }
  };

  // Password Validation
  const validatePassword = (pwd) => {
    const lengthValid = pwd.length >= 8 && pwd.length <= 36;
    const uppercaseValid = /[A-Z]/.test(pwd);
    const numberValid = /\d/.test(pwd);
    const specialCharValid = /\W/.test(pwd);

    if (!lengthValid) return "Password must be 8-36 characters.";
    if (!uppercaseValid)
      return "Password must include at least one uppercase letter.";
    if (!numberValid) return "Password must include at least one number.";
    if (!specialCharValid)
      return "Password must include at least one special character.";
    return "";
  };

  useEffect(() => {
    if (password && password.length > 0 && loginState === "signup") {
      const message = validatePassword(password);
      setPasswordMessage(message ? message : "Strong password");
    } else {
      setPasswordMessage(null);
    }
  }, [password, loginState]);

  // Confirm Password Validation
  useEffect(() => {
    if (confirmPassword) {
      setConfirmPasswordMessage(
        confirmPassword === password
          ? "Passwords match"
          : "Passwords do not match"
      );
    } else {
      setConfirmPasswordMessage(null);
    }
  }, [confirmPassword, password]);

  // Email Validation
  useEffect(() => {
    if (email && email !== "") {
      const emailValid = /\S+@\S+\.\S+/.test(email);
      setEmailMessage(emailValid ? null : "Invalid email format.");
    } else {
      setEmailMessage(null);
    }
  }, [email]);

  const handleEmailSignup = async () => {
    if (!validateSignup()) return;
  
    const displayNameAvailable = await ScribiRestService.checkDisplayNameAvailable(displayName);
    if (!displayNameAvailable) {
      setDisplayNameMessage("Display name already in use.");
      return;
    }
    if (validatePassword(password)) return;
    if (confirmPassword !== password) return;
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      let user = userCredential.user;
      await updateProfile(user, { displayName });
      await ScribiRestService.createUserDoc(user, displayName);
  
      // Send verification email to the new user
      await sendEmailVerification(userCredential.user);
      
      // Sign out the user to enforce email verification before access
      await auth.signOut();

      setLoginState("login");
  
      // Show a message or redirect
      setModal({
        visible: true,
        message: "A verification email has been sent. Please verify your email before logging in.",
        title: "Email Verification",
        confirm: () => setModal({ visible: false }),
      });
  
    } catch (error) {
        
      setModal({
        visible: true,
        message: error.message,
        title: "Error",
        confirm: () => setModal({ visible: false }),
      });
    }
  };

  const handleLoginWithEmail = async () => {
    if (!validateLogin()) return;
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      if (!user.emailVerified) {
        setUnverifiedUser(user);
        // If not verified, sign the user out immediately and prompt verification
        await auth.signOut();
  
        setModal({
          visible: true,
          message: "Please verify your email before logging in. If you've previously signed up with this email, an email verification link should have been sent to your inbox. Check your spam folder just in case. The email will come from admin@scribi.app. If you can't find it, use the button below to send a new verification link.",
          title: "Email Verification Required",
          confirm: () => setModal({ visible: false }),
        });
  
        // Send verification email again if needed
        return;
      }
  
      // If verified, proceed with login (redirect, etc.)
      navigate("/");
    } catch (error) {
        console.log(error);
        
      setModal({
        visible: true,
        message: "Incorrect email or password.",
        title: "Login Error",
        confirm: () => setModal({ visible: false }),
      });
    }
  };

  const handleLogInWithGoogle = async () => {
    try {
      // Step 1: Sign in with Google
      const result = await signInWithPopup(auth, provider);
  
      // Get the user's UID and email from the result
      const userId = result.user.uid;
      const email = result.user.email.toLowerCase();
  
      // Step 2: Check if a user document exists
      const response = await ScribiRestService.checkUserDoc(userId, email);
  
      if (response.exists) {
        // User document exists, navigate to home
        navigate("/");
      } else {
        // Clean up the user's display name, removing spaces and special characters other than _
        const cleanedDisplayName = result.user.displayName
          .replace(/[^\w\s]/gi, "")
          .replace(/ /g, "_");
        
        // Update displayName on the user's profile
        await updateProfile(result.user, { displayName: cleanedDisplayName });
  
        // User document does not exist, create a new one
        const createResponse = await ScribiRestService.createUserDoc(result.user, cleanedDisplayName);
  
        if (createResponse.created) {
          navigate("/");
        } else {
          throw new Error("Failed to create user document.");
        }
      }
    } catch (error) {
      setModal({
        visible: true,
        message: error.message,
        title: "Login Error",
        confirm: () => setModal({ visible: false }),
      });
    }
  };

  const handlePasswordReset = async () => {
    if (!validateForgot()) return;
    try {
      await sendPasswordResetEmail(auth, email);
      setModal({
        visible: true,
        message: "If registered, a password reset email has been sent.",
        title: "Password Reset",
        confirm: () => setModal({ visible: false }),
      });
    } catch (error) {
      setEmailMessage(error.message);
    }
  };

  const handleResendVerificationEmail = async () => {
    if (unverifiedUser) {
      try {
        await sendEmailVerification(unverifiedUser);
        setModal({
          visible: true,
          message: "Verification email resent. Please check your inbox.",
          title: "Verification Email Sent",
          confirm: () => setModal({ visible: false }),
        });
        setUnverifiedUser(null); // Clear after resending
      } catch (error) {
        setModal({
          visible: true,
          message: "Error sending verification email. Please try again later.",
          title: "Error",
          confirm: () => setModal({ visible: false }),
        });
      }
    }
  };

  return (
    <FormBlock>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fsplash-title-graphic.png?alt=media&token=ec72c181-526e-423f-ba79-8fb95d1d1b7f"
        alt="Scribi Logo"
        className="logoText"
      />
      {loginState !== "verify" && (
        <>
          {loginState === "signup" && (
            <>
              <TextInput
                type="text"
                placeholder="Display Name"
                value={displayName}
                onChange={handleDisplayNameChange}
              />
              {displayNameMessage && (
                <ErrorLabel valid={!displayNameMessage.includes("invalid")}>
                  {displayNameMessage}
                </ErrorLabel>
              )}
            </>
          )}
          <TextInput
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailMessage && <ErrorLabel>{emailMessage}</ErrorLabel>}
          {["signup", "login"].includes(loginState) && (
            <>
              <TextInput
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={(e) => {
                  if (loginState === "signup") {
                    setPasswordMessage(validatePassword(password));
                  }
                }}
              />
              {passwordMessage && (
                <ErrorLabel valid={passwordMessage === "Strong password"}>
                  {passwordMessage}
                </ErrorLabel>
              )}
            </>
          )}
          {loginState === "signup" && (
            <>
              <TextInput
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {confirmPasswordMessage && (
                <ErrorLabel
                  valid={confirmPasswordMessage === "Passwords match"}
                >
                  {confirmPasswordMessage}
                </ErrorLabel>
              )}
            </>
          )}
          {loginState === "login" && (
            <>
            {unverifiedUser && (
                <ConfirmButton onClick={handleResendVerificationEmail}>
                    Resend Verification Email
                </ConfirmButton>
            )}
            <ConfirmButton onClick={handleLoginWithEmail}>Login</ConfirmButton>
            </>
          )}
          {loginState === "signup" && (
            <ConfirmButton onClick={handleEmailSignup}>Sign Up</ConfirmButton>
          )}
          {loginState === "forgot" && (
            <ConfirmButton onClick={handlePasswordReset}>
              Reset Password
            </ConfirmButton>
          )}
          <label>Or:</label>
          <ConfirmButton onClick={handleLogInWithGoogle}>
            Login with Google
          </ConfirmButton>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            {["signup", "login"].includes(loginState) && (
              <HeaderLink onClick={() => setLoginState("forgot")}>
                Forgot Password?
              </HeaderLink>
            )}
            {["login", "forgot"].includes(loginState) && (
              <HeaderLink onClick={() => setLoginState("signup")}>
                Sign Up
              </HeaderLink>
            )}
            {["signup", "forgot"].includes(loginState) && (
              <HeaderLink onClick={() => setLoginState("login")}>
                Log In
              </HeaderLink>
            )}
          </div>
        </>
      )}
      {loginState === "verify" && (
        <>
          <label>
            If you've provided a valid email address, you'll recieve a
            confirmation email momentarily. When you do, use the link in the
            email to verify your email address and you'll be all set.
          </label>
        </>
      )}
    </FormBlock>
  );
};

export default AuthPage;
