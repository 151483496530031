import React, { useEffect, useState } from "react";
import JumboSplash from "./Splash/JumboSplash";
import styled from "styled-components";
import Icons from "./Icons/Icons";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const FeatureDisplayContainer = styled(Container)`
  padding: 4rem 0;
  color: #060606;

  h2 {
    margin-bottom: 3rem;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .feature-section {
    margin-bottom: 4rem;
  }

  .feature-icon {
    font-size: 3rem;
    color: #0e4a73;
    margin-bottom: 1rem;
  }

  .feature-card {
    border: none;
    background-color: #f9f9f9;
    text-align: center;
    height: 100%;
  }

  .feature-card .card-body {
    padding: 2rem 1rem;
  }

  .card-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }

  .card-text {
    font-size: 1rem;
    color: #555;
  }
`;

const LearnButton = styled(Button)`
  background-color: #e5e7eb;
  color: #0e4a73;
  border: none;
  padding: 12px 24px;
  font-weight: 600;
  transition: all 0.2s ease;
  margin-top: 2rem;

  &:hover {
    background-color: #0e4a73;
    color: #e5e7eb;
  }
`;

const Home = () => {
  const [openFeature, setOpenFeature] = useState(null);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const features = [
    {
      id: 1,
      icon: <Icons.Brain />,
      title: `End-to-End Writing Support`,
      description: `
        Seamlessly transition from ideas to a polished manuscript with tools that assist in brainstorming, 
        outlining, drafting, and publishing. Capture your process and reuse it for your next project.
      `,
      details: [
        {
          subheading: `From First Spark to Reader-Ready`,
          description: `
            Scribi provides tools for every stage of the writing process that you can mix, match, and customize to fit your needs. 
            The Brainstorm module can help you work out details for everything from plot to world-building.
          `,
        },
      ],
    },
    {
      id: 2,
      icon: <Icons.FileExport />,
      title: `Flexible Export Options`,
      description: `Easily format and export your work as a PDF for print, a beautifully styled eBook for publication, or as DOCX/RTF files 
        for editors or agents. Re-import edited manuscripts with ease, or import your entire catalog in one go.`,
      details: [
        {
          subheading: `Easy Import/Export`,
          description: `Importing and exporting is as simple as drag-and-drop. Scribi handles the tedious details around 
            formatting and exporting, allowing you to focus on writing.`,
        },
      ],
    },
    {
      id: 3,
      icon: <Icons.Book />,
      title: `Innovative Book Block System`,
      description: `Customize your front and back matter with reusable templates. Update elements like calls to action or 
        newsletter links across your entire catalog with minimal effort. Keep your branding consistent and professional, 
        and your eBook files up to date with just a few clicks.
      `,
      details: [
        {
          subheading: `Reusable Templates`,
          description: `Create templates for front and back matter to ensure consistency across all your publications.`,
        },
      ],
    },
    {
      id: 4,
      icon: <Icons.Shapes />,
      title: `Powerful Templating`,
      description: `Create and refine templates for brainstorming, beat sheets, outlines, and manuscripts. Tailor your 
        templates to fit different genres, series, or pen names, and reuse them in seconds. Start with Scribi's included 
        templates, build your own from scratch, or import templates from other users.
      `,
      details: [
        {
          subheading: ``,
          description: ``,
        },
      ],
    },
    {
      id: 5,
      icon: <Icons.Database />,
      title: `Integrated Storybase`,
      description: `Manage characters, locations, items, and world-building details in a flexible, searchable database alongside your manuscript. 
        Reference and tag elements without leaving the page. By combining the author's story bible with a full relational database system, 
        Scribi puts your entire canon at your fingertips in an instant.`,
      details: [
        {
          subheading: ``,
          description: ``,
        },
      ],
    },
    {
      id: 6,
      icon: <Icons.RegClock />,
      title: `Comprehensive Timeline Feature`,
      description: `Organize events chronologically or narratively, customize calendars for your fictional worlds, and track 
        the progression of story elements over time. Simply track days and times for consistency, or build entire epochs of history 
        for your epic story world.
      `,
      details: [
        {
          subheading: ``,
          description: ``,
        },
      ],
    },
    {
      id: 7,
      icon: <Icons.PuzzlePiece />,
      title: `Modular Design`,
      description: `
        Scribi's modular design allows you to customize your workspace to fit your needs. Don’t need a timeline? Hide it. Prefer 
        to go from beat sheet straight to the manuscript? Leave other modules out. The goal is to give you everything you need,
        while keeping distractions minimal until you want them.
      `,
      details: [
        {
          subheading: ``,
          description: ``,
        },
      ],
    },
    {
      id: 8,
      icon: <Icons.Tools />,
      title: `More Tools to Come`,
      description: `
        Scribi is under constant development, with new features and tools being created and refined on a regular 
        basis. A conlang module for developing fantasy languages is already in the works, and integration with 
        AI tools is on the horizon.
      `,
      details: [
        {
          subheading: ``,
          description: ``,
        },
      ],
    },
  ];
  useEffect(() => {
    setOpenFeature(null);
  }, [isMobile]);

  return (
    <>
      <JumboSplash />
      <FeatureDisplayContainer>
        <h2>Your All-in-One Writing Solution</h2>
     
        {/* Key Features Section */}
        <div className="feature-section">
          <h3 className="text-center mb-5">Key Features</h3>
          <Row className="g-4">
            {features.map((feature) => (
              <Col md={4} key={feature.id}>
                <FeatureCard
                  id={feature.id}
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                  details={feature.details}
                  openFeature={openFeature}
                  setOpenFeature={setOpenFeature}
                />
              </Col>
            ))}
          </Row>
        </div>

        {/* Why Choose Scribi Section */}
        <div className="feature-section">
          <h3 className="text-center mb-4">Why Choose Scribi?</h3>
          <p
            style={{
              maxWidth: "800px",
              margin: "0 auto",
              textAlign: "center",
              fontSize: "1.1rem",
              color: "#555",
            }}
          >
            Scribi is built by an author who understands the unique challenges
            and needs of the writing profession. It eliminates the need for
            multiple disparate tools by consolidating essential writing and
            publishing functionalities into one intuitive piece of software. It is the 
            only tool of its kind available today, providing features that will change 
            the way you work and the way you think about your writing process. Once you 
            see the difference Scribi can make, you'll wonder how you ever wrote without it,
            and you'll be fully empowered and equipped to take your catalog to the next level.
          </p>
        </div>

        {/* Who Can Benefit Section */}
        <div className="feature-section">
          <h3 className="text-center mb-5">Who Can Benefit?</h3>
          <Row className="g-4">
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <Card.Title>Indie Authors</Card.Title>
                  <Card.Text>
                    Streamline your writing and publishing process, manage your
                    catalog efficiently, and maintain brand consistency across Your
                    works. Grow your catalog faster than you ever thought possible. With Scribi's 
                    Storybase system, you can keep track of every detail in your story world,
                    and tell deeper and more immersive stories than ever. That's a recipe for 
                    a dedicated fanbase and a successful career.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <Card.Title>Aspiring Authors</Card.Title>
                  <Card.Text>
                    Access templates and resources to guide you through writing
                    in various genres, helping you develop your unique voice and
                    style. Scribi's template library provides a starting point for 
                    books in several genres, and will continue to grow, providing more 
                    and more resources on everything from pen name branding and marketing 
                    to breaking into new genres.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <Card.Title>Traditional Authors</Card.Title>
                  <Card.Text>
                    Your publisher may have a team of editors, designers, and marketers,
                    but nothing will give you or your the team the kind of insight into your
                    story that Scribi can. That streamlines the process for both you and your 
                    editor or agent, and the smoother the process, the faster your manuscript 
                    hits the shelves. 
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>

        {/* Call to Action Section */}
        <div className="feature-section" style={{ textAlign: "center" }}>
          <h3>Experience Writing Without Boundaries</h3>
          <p
            style={{
              maxWidth: "800px",
              margin: "0 auto",
              fontSize: "1.1rem",
              color: "#555",
            }}
          >
            Focus on crafting compelling stories while Scribi takes care of the
            rest. Embrace a writing studio that evolves with your process,
            supports your creativity, and simplifies the complexities of writing
            and publishing.
          </p>
          <LearnButton href="/download">
            Try Scribi Free for 14 Days <Icons.DownloadIcon />
          </LearnButton>
        </div>
      </FeatureDisplayContainer>
    </>
  );
};

export default Home;

const FeatureCard = ({
  id,
  icon,
  title,
  description,
  details,
  openFeature,
  setOpenFeature,
}) => {

  return (
    <Card className="feature-card h-100">
      <Card.Body>
        <div className="feature-icon">{icon}</div>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
        {/* <Button
          variant="link"
          className="learn-more-button"
          onClick={toggleFeature}
        >
          {isMobile ? (isOpen ? "Show Less" : "Learn More") : "Learn More"}
        </Button>

        {isMobile ? (
          // Mobile: Use Collapse
          <Collapse in={isOpen}>
            <div className="mt-3">
              {details.map((detail, index) => (
                <div key={index}>
                  <h5>{detail.subheading}</h5>
                  <p>{detail.description}</p>
                </div>
              ))}
            </div>
          </Collapse>
        ) : (
          // Desktop/Tablet: Use Modal
          <Modal
            show={isOpen}
            onHide={() => setOpenFeature(null)}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {details.map((detail, index) => (
                <div key={index}>
                  <h5>{detail.subheading}</h5>
                  <p>{detail.description}</p>
                </div>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setOpenFeature(null)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )} */}
      </Card.Body>
    </Card>
  );
};
