import React, {  } from "react";
import JumboSplash from "./Splash/JumboSplash";
import styled from "styled-components";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
} from "react-bootstrap";
import { ScribiNavLink } from "./Scribi-Components/ScribiComponents";
import ScribiRestService from "./utilities/firestore/ScribiRestService";

const FeatureDisplayContainer = styled(Container)`
  padding: 4rem 0;
  color: #060606;

  h2 {
    margin-bottom: 3rem;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .feature-section {
    margin-bottom: 4rem;
  }

  .feature-icon {
    font-size: 3rem;
    color: #0e4a73;
    margin-bottom: 1rem;
  }

  .feature-card {
    border: none;
    background-color: #f9f9f9;
    text-align: center;
    height: 100%;
  }

  .feature-card .card-body {
    padding: 2rem 1rem;
  }

  .card-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }

  .card-text {
    font-size: 1rem;
    color: #555;
  }
`;



const ButtonBase = styled(ScribiNavLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
`;

const BuyButton = styled(ScribiNavLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
  justify-content: center;
  background-color: #e5e7eb;
  color: #0e4a73;
  border: none;
  &:hover {
    background-color: #e5e7eb;
  }
`;

const PrimaryButton = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5e7eb;
  color: #0e4a73;
  border: none;
  width: auto;
  &:hover {
    background-color: #e5e7eb;
  }
`;

const Download = () => {
  return (
    <>
      <JumboSplash />
      <FeatureDisplayContainer>
        <h2>Coming Soon!</h2>
        <p
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            marginBottom: "0.5rem",
            textAlign: "center",
            fontSize: "1.1rem",
            color: "#555",
          }}
        >
          Scribi is in the final stages of development and will be available for
          purchase soon. You'll be able to download Scribi for a free trial once
          it's ready, to decide whether you want to purchase it for good. Unlike
          a lot of desktop software these days, you'll buy Scribi just once and
          won't have to pay for version 2 or 3, or buy a license for both
          Windows and Mac. I use Scribi for my work, too, so future features
          will be developed for my benefit and, by extension, yours! That's not
          dependent on sales, like it is with some other apps. You also don't
          have to subscribe to a service to access it. Scribi is a standalone
          desktop application that will replace every other software you
          regularly use for writing and managing your catalog to centralize your
          entire workflow in one place from first idea to publication.
        </p>
        <p
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            textAlign: "center",
            fontSize: "1.1rem",
            color: "#555",
          }}
        >
          I do want to make sure that Scribi is accessible to everyone who wants
          to use it. So you have the option below to either purchase a full
          license outright, or essentially 'lease to own' for $12 a month, for
          folks who maybe are just starting out or who simply don't want to
          fully invest until they've had more time to play with it. If you
          decide to go monthly and eventually reach a point where you want to
          pay off your license, you'll be able to do that as well. Just log in
          to the website and if you have remaining payments you'll see an option
          in the header to pay off Scribi. The remaining payments will be based
          on a $129 license split into 12, so if you do pay your license off
          early you'll get a discount to future payments based on how early
          you're paying it off. This is the best way I can think of to make
          Scribi accessible to everyone.
        </p>
        <div className="feature-section">
          <Row className="g-2">
            <Col md={6}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <Card.Title>Buy for $129</Card.Title>
                  <Card.Text>
                    One-time purchase. Scribi is yours for good, including all
                    future updates.
                  </Card.Text>
                </Card.Body>
                <Card.Footer style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <BuyButton
                  to="/login"
                    // onClick={async () => {
                    //   // let { url } =
                    //   //   await ScribiRestService.createStripeSession(
                    //   //     "price_1QG4YqGNKV5mxzQaB2iQ1Cdl", "payment"
                    //   //   );
                    //   // console.log(url);
                    //   // window.open(url, "_blank");
                    // }}
                  >
                    Buy Now
                  </BuyButton>
                </Card.Footer>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <Card.Title>Buy for $12/month for a year</Card.Title>
                  <Card.Text>
                    This is <em>not</em> an indefinite subscription. After 12
                    months, your license is permanently yours. This is an option
                    for folks who can't or don't want to put down $129 all at
                    once, though it does bring the total price to $144. If you
                    pause or cancel your payments, your license will be
                    suspended until you resume payments. On a suspended license,
                    you'll be able to export existing work from Scribi but you
                    won't be able to add new work.
                  </Card.Text>
                </Card.Body>
                <Card.Footer style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <BuyButton
                  to="/login"
                   //</Card.Footer> onClick={async () => {
                      // let {url} =
                      //   await ScribiRestService.createStripeSession(
                      //     "price_1QG4ZWGNKV5mxzQa7XMOSxZB", "subscription"
                      //   );
                      // console.log(url);
                      // //Open a new tab to the stripe checkout session
                      // window.open(url, "_blank");
                   //}}
                  >
                    Start Payments Now
                  </BuyButton>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </div>
        <p
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            textAlign: "center",
            fontSize: "1.1rem",
            color: "#555",
          }}
        >
          Wanna know what's coming? Check out the roadmap!
        </p>
        <PrimaryButton to="/roadmap">View the Roadmap</PrimaryButton>
      </FeatureDisplayContainer>
    </>
  );
};

export default Download;
