import React from "react";
import {
  Jumbotron,
  JumbotronContainer,
  ScribiNavLink,
} from "../Scribi-Components/ScribiComponents";
import styled from "styled-components";
import Icons from "../Icons/Icons";

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 600px;
  z-index: 1;
`;

const HeadlineGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: white;
    line-height: 1.2;
    margin: 0;
  }

  p {
    font-size: 1.25rem;
    color: #e5e7eb;
    line-height: 1.5;
    margin: 0;
  }
`;

const CTAGroup = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const ButtonBase = styled(ScribiNavLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
`;

const PrimaryButton = styled(ButtonBase)`
  background-color: white;
  color: #0e4a73;
  border: none;

  &:hover {
    background-color: #e5e7eb;
  }
`;

const SecondaryButton = styled(ButtonBase)`
  background-color: transparent;
  color: white;
  border: 2px solid white;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const JumboSplash = () => {

  return (
    <JumbotronContainer>
      <Jumbotron>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fsplash-logo.png?alt=media&token=f47bc348-5f10-4b8e-b4b8-6bbc801b135f"
          alt="Scribi Logo"
          className="logo"
        />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fsplash-overlay.png?alt=media&token=1796ab9b-42ea-45f1-9448-022f8345c4ce"
          alt="Scribi Logo"
          className="overlay"
        />
        <ContentSection>
          <HeadlineGroup>
            <h1>a new era for authors is here</h1>
            <p>The working author's end-to-end studio software that adapts to <em>your</em> creative process, not the other way around.</p>
          </HeadlineGroup>
          <CTAGroup>
            <PrimaryButton to="/download">
              Get Scribi
              <Icons.DownloadIcon />
            </PrimaryButton>
            <SecondaryButton to="/about">
              The Story So Far
              <Icons.ArrowRight />
            </SecondaryButton>
          </CTAGroup>
        </ContentSection>
      </Jumbotron>
    </JumbotronContainer>
  );
};

export default JumboSplash;
