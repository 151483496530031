import React from "react";
import {
  BodyContainer,
  HrDivider,
  ScribiLink,
  ScribiNavLink,
} from "../Scribi-Components/ScribiComponents";

const Policies = () => {
  return (
    <BodyContainer>
      <div
        style={{ maxWidth: "1000px", marginLeft: "auto", marginRight: "auto" }}
      >
        <h2>
          <strong>Scribi's Privacy Policy</strong>
        </h2>
        <p>
          TLDR: Scribi doesn't collect any personal information beyond your
          login and, if you choose to use Google OAuth for your login,
          information related to your google account that helps Scribi connect
          your account with services like Google Calendar and Google Docs.
        </p>
        <p>
          However, we don't share any information about you, we don't sell any
          information about you, we don't track your activity on the
          application, we don't use any marketing cookies or even analytics. If
          we ever change that you'll be notified, but so long as Scribi is run
          by Brian Palmer, the creator of the app, that's really beyond the
          scope of what Scribi is for.
        </p>
        <p>
          That said, for legal reasons, here is the Privacy Policy full monty:
        </p>
        <HrDivider />

        <p>
          Scribi, LLC operates the https://scribi.app website, which provides
          the SERVICE.
        </p>

        <p>
          This page is used to inform website visitors regarding our policies
          with the collection, use, and disclosure of Personal Information if
          anyone decided to use our Service, the Scribi website.
        </p>

        <p>
          If you choose to use our Service, then you agree to the collection and
          use of information in relation with this policy. The Personal
          Information that we collect are used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.
        </p>

        <p>
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which is accessible at{" "}
          <ScribiNavLink to={"/terms-and-conditions"}>
            https://scribi.app/terms-and-conditions
          </ScribiNavLink>
          , unless otherwise defined in this Privacy Policy.
        </p>

        <h3>Information Collection and Use</h3>

        <p>
          For a better experience while using our Service, we may require you to
          provide us with certain personally identifiable information, including
          but not limited to your name and email. The information that we
          collect will be used to contact or identify you.
        </p>

        <p>
          Additionally, our Service allows for optional integration with
          third-party services such as Google Cloud for functionalities like
          calendar access and Google Drive storage. Should you choose to connect
          these third-party services to Scribi, we will have read and write
          access to the specific data you authorize. Importantly, Scribi, LLC
          does not store, evaluate, or otherwise use any data from these
          integrated third-party services for purposes other than to facilitate
          the functionality for which you have granted permission.
        </p>

        <h3>Log Data</h3>

        <p>
          We want to inform you that whenever you visit our Service, we collect
          information that your browser sends to us that is called Log Data.
          This Log Data may include information such as your computer's Internet
          Protocol ("IP") address, browser version, pages of our Service that
          you visit, the time and date of your visit, the time spent on those
          pages, and other statistics. This information can be used to identify
          you to legal authorities in the event that your data is subpoenaed by
          a court of law, or if content you produce and store on our servers is
          found to be in violation of the law.
        </p>

        <h3>Cookies</h3>

        <p>
          Cookies are files with small amount of data that is commonly used an
          anonymous unique identifier. These are sent to your browser from the
          websites that you visit and are stored on your computer's hard drive.
        </p>

        <p>
          Our website uses these "cookies" to improve our Service by allowing
          you to remain logged in and to authenticate with our servers. You have
          the option to either accept or refuse these cookies, and know when a
          cookie is being sent to your computer. If you choose to refuse our
          cookies, you may not be able to use some portions of our Service. We
          do not utilize cookies to collect any data about your use of our
          services or to track your activity on the internet in any way.
        </p>

        <h3>Service Providers</h3>

        <p>
          We may employ third-party companies and individuals due to the
          following reasons:
        </p>

        <ul>
          <li>To facilitate our Service;</li>
          <li>To provide the Service on our behalf; or</li>
          <li>To perform Service-related services.</li>
        </ul>

        <p>
          We want to inform our Service users that these third parties have
          access to the limited Personal Information that we utilize. The reason
          is to perform the tasks assigned to them on our behalf. However, they
          are obligated not to disclose or use the information for any other
          purpose.
        </p>

        <p>
          We utilize the following third party services, and you may access
          their respective privacy policies at the following links:
        </p>

        <ul>
          <li>
            <ScribiLink href="https://www.stripe.com/privacy">
              Stripe's Privacy Policy
            </ScribiLink>
          </li>
          <li>
            <ScribiLink href="https://policies.google.com/privacy">
              Google's Privacy Policy
            </ScribiLink>
          </li>
        </ul>

        <h3>Security</h3>

        <p>
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security. We do not store any of your
          personal information on our servers. All of your personal information
          is stored on Google's servers and is subject to their security
          protocols. Any financial data associated with your Scribi account is
          stored on Stripe's servers.
        </p>

        <h3>Links to Other Sites</h3>

        <p>
          Our Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over, and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>

        <p>Children's Privacy</p>

        <p>
          Our Services do not address anyone under the age of 13. We do not
          knowingly collect personal identifiable information from children
          under 13. In the case we discover that a child under 13 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to do necessary actions.
        </p>

        <h3>Changes to This Privacy Policy</h3>

        <p>
          We may update our Privacy Policy from time to time. Thus, we advise
          you to review this page periodically for any changes. We will notify
          you of any changes by posting the new Privacy Policy on this page.
          These changes are effective immediately, after they are posted on this
          page.
        </p>

        <h3>Contact Us</h3>

        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at{" "}
          <ScribiLink href="mailto:admin@scribi.app" style={{ color: "white" }}>
            admin@scribi.app
          </ScribiLink>
          .
        </p>
      </div>
    </BodyContainer>
  );
};

export default Policies;
