import React from "react";

const DocumentContext = React.createContext();

const DocumentContextProvider = ({ children }) => {
    const [document, setDocument] = React.useState([]);
    const [parent, setParent] = React.useState(null);
    const [grandparent, setGrandparent] = React.useState(null);
    const [indexMap, setIndexMap] = React.useState(null);

    const contextValue = React.useMemo(() => {
        return {
            document,
            setDocument,
            parent,
            setParent,
            grandparent,
            setGrandparent,
            indexMap,
            setIndexMap,
        };
    }
    , [document, setDocument, parent, setParent, grandparent, setGrandparent, indexMap, setIndexMap]);

    return (
        <DocumentContext.Provider
            value={contextValue}
        >
            {children}
        </DocumentContext.Provider>
    );
}

export { DocumentContext, DocumentContextProvider };