import styled from "styled-components";
import Icons from "../Icons/Icons";
import { Bar, Container, Section } from "@column-resizer/react";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { EditorContent } from "@tiptap/react";

export const Themes = {
  scribi: {
    primary: "#0e4a73",
    secondary: "#097ab8",
    tertiary: "#03a8e6",
    cancel: "#e7322a",
    cancelDark: "#8a201c",
    formText: "black",
    textLightColor: "white",
    iconColor: "black",
    background: "white",
    formBackground: "white",
    calendarPrimary: "#ffffff",
    calendarSecondary: "aliceblue",
    calendarTertiary: "lightgray",
    darkHighlightColor: "dodgerblue",
    timeBorderColor: "#5f5f5f",
    menuBackground: "#ffffff",
    menuHighlight: "#3b3b3b",
    workEvent: "#0e4a73",
  },
  dark: {
    primary: "#3b3c3d",
    secondary: "#525252",
    tertiary: "#1d1e1f",
    bodyBackground: "#7e7e7e",
    cancel: "#e7322a",
    cancelDark: "#8a201c",
    formText: "white",
    textLightColor: "white",
    iconColor: "white",
    background: "#636161",
    formBackground: "#2c2c2c",
    calendarPrimary: "#a1a1a1",
    calendarSecondary: "#7d7d7d",
    calendarTertiary: "#535353",
    darkHighlightColor: "#343434",
    timeBorderColor: "#5f5f5f",
    menuBackground: "#4c4c4c",
    menuHighlight: "#3b3b3b",
    workEvent: "#0e4a73",
    toolbarButton: "#c7c7c7",
  },
  tempHolder: {
    primary: "#0e4a73",
    secondary: "#097ab8",
    tertiary: "#03a8e6",
    cancel: "#e7322a",
    cancelDark: "#8a201c",
    formText: "black",
    textLightColor: "white",
    background: "white",
    formBackground: "white",
  },
};

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  background-color: transparent;
  color: ${({ theme }) => theme.formText};
`;

export const FormTitleInput = styled.input`
  font-size: 1.5rem;
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 2px solid #0e4a73;
  color: ${({ theme }) => theme.formText};
  &::placeholder {
    color: ${({ theme }) => theme.formText};
    opacity: 0.5;
  }
`;

export const FormInput = styled.input`
  outline: none;
  background-color: ${({ theme }) => theme.formBackground};
  color: ${({ theme }) => theme.formText};
  border-radius: 5px;
  border: 1px solid #0e4a73;
  padding: 5px;
  width: 100%;
  font-size: 1rem;
`;

export const FormLabeledCheckBox = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  background-color: transparent;
  input {
    width: 15px;
    height: 15px;
    border-radius: 5px;
  }
`;

export const FormTextArea = styled.textarea`
  outline: none;
  background-color: ${({ theme }) => theme.formBackground};
  color: ${({ theme }) => theme.formText};
  border-radius: 5px;
  border: 1px solid #0e4a73;
  padding: 10px;
  width: 100%;
`;

export const FormRow = styled.div`
    display: flex;
    flex-direction: row;
      background-color: transparent;

    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: ${(props) => props.justifyContent || "flex-start"}};
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  gap: 10px;
  align-items: center;
  input.grid-checkbox {
    margin: auto 0;
    height: 1rem;
  }
`;

export const FormSelect = styled.select`
  outline: none;
  background-color: ${({ theme }) => theme.formBackground};
  color: ${({ theme }) => theme.formText};
  border-radius: 5px;
  border: 1px solid #0e4a73;
  padding: 5px;
  width: 100%;
  font-size: 1rem;
`;

export const FormSectionTitle = styled.label`
  font-size: 1.2rem;
  font-weight: 600;
  background-color: transparent;
  grid-column: ${({ gridColumn }) => gridColumn};
`;

export const DocumentHeading = styled.input`
  font-size: 1.2rem;
  font-weight: 600;
  background-color: transparent;
  border: none;
  padding: none;
  color: ${({ theme }) => theme.formText};
  text-align: center;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.formText};
    opacity: 0.5;
    font-style: italic;
    font-weight: 400;
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: transparent;
`;

export const FormTitle = styled.h4`
  margin: 0;
  background-color: transparent;
`;

export const FormLabel = styled.label`
  font-size: 1rem;
  color: ${({ theme }) => theme.formText};
  white-space: nowrap;
  padding: 5px;
`;

export const FormGridTitle = styled.h5`
  color: ${({ theme }) => theme.formText};
  grid-column: ${({ gridColumn }) => gridColumn};
`;

export const FormGridHeading = styled.h6`
  color: ${({ theme }) => theme.formText};
  margin: 0;
`;

export const NavItem = styled.div`
  display: grid;
  grid-template-columns: 25px 25px auto;
  width: 100%;
  align-items: center;
  background-color: ${({ theme, selected }) =>
    selected ? theme.primary : "transparent"};
  button.toggle {
    grid-column: 1;
    border: none;
    background-color: transparent;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    svg {
      fill: ${({ theme }) => theme.iconColor};
      color: ${({ theme }) => theme.iconColor};
      height: 10px;
      width: 10px;
    }
  }
  div.icon {
    grid-column: 2;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: left;
    svg {
      fill: ${({ theme }) => theme.iconColor};
      color: ${({ theme }) => theme.iconColor};
      width: 15px;
    }
  }
  label.title {
    grid-column: 3;
    font-size: 1rem;
    font-weight: 600;
    user-select: none;
    cursor: pointer;
  }
  input.title {
    grid-column: 3;
    padding: 0px;
    font-size: 1rem;
  }
`;

export const HrDivider = styled.hr`
  color: black;
  background-color: black;
  height: 1px;
  margin: 0px;
  grid-column: ${({ gridColumn }) => gridColumn};
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 1rem;
  min-height: 1rem;
  svg {
    color: black;
    font-size: 1rem;
    height: "1rem";
    width: "1rem";
  }
  img {
    height: ${({ size }) => (size ? `${size}px` : "25px")};
    width: ${({ size }) => (size ? `${size}px` : "25px")};
    border-radius: 100%;
  }
`;

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: relative;
  color: ${({ theme }) => theme.formText};
  background-color: #ffffff;
  svg {
    fill: ${({ theme }) => theme.formText};
  }
`;

export const AppHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  gap: 20px;
  background: #ffffff;
  height: 50px;
  min-height: 50px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  svg {
    color: black;
    font-size: 1.2rem;
    height: 1.5rem;
    width: 1.5rem;
  }
  a {
    @media (max-width: 1000px) {
      display: none;
    }
  }
  button.mobile-menu-button {
    display: none;
    @media (max-width: 1000px) {
      display: flex;
    }
  }
`;
export const WorkAppHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: right;
  gap: 20px;
  background: #ffffff;
  height: 50px;
  min-height: 50px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  svg {
    color: white;
    font-size: 1.2rem;
  }
  a {
    @media (max-width: 1000px) {
      display: none;
    }
  }
  button.mobile-menu-button {
    display: none;
    @media (max-width: 1000px) {
      display: flex;
    }
  }
  div.header-section-right {
    grid-column: 3;
    button.work-header-button {
      svg {
        color: white;
        fill: white;
        font-size: 1.2rem;
        &:hover {
          color: #03a8e6;
          fill: #03a8e6;
        }
      }
      @media (max-width: 1000px) {
        display: none;
      }
    }
  }
`;

export const HeaderLink = styled(Link)`
  color: #00aaff;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  &:hover {
    color: #03a8e6;
  }
`;

export const JumbotronContainer = styled.div`
  background: linear-gradient(
    to right,
    #0e4a73 0%,
    #0e4a73 50%,
    #097ab8 50%,
    #097ab8 100%
  );
  width: 100%;
`;

export const Jumbotron = styled.div`
  background: linear-gradient(
    to right,
    #0e4a73 0%,
    #0e4a73 60%,
    #097ab8 60%,
    #097ab8 100%
  );
  width: 1000px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: no-wrap;
  gap: 50px;
  padding: 30px;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    background: linear-gradient(
      to right,
      #0e4a73 0%,
      #0e4a73 60%,
      #097ab8 60%,
      #097ab8 100%
    );
    width: 100%;
    flex-direction: column;
  }

  img.logo {
    height: 70%;
    max-height: 200px;
    z-index: 1;
  }

  img.overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    @media (max-width: 1000px) {
      width: 50%;
      height: 100%;
    }
    z-index: 0;
  }

  div.jumbo-auth-block {
    display: flex;
    flex-direction: column;
    z-index: 1;
    gap: 10px;
    height: 300px;
    width: 325px;
  }

  img.logoText {
    z-index: 1;
    height: 100px;
  }
`;

//A styled text input called TextInput:
export const TextInput = styled.input`
  font-size: 1rem;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #0e4a73;
  &:focus {
    outline: none;
    border: 1px solid #097ab8;
  }
`;

//A styled button called ConfirmButton, which should be a tertiary color to the primary color of the app:
export const ConfirmButton = styled.button`
  font-size: 1rem;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #0e4a73;
  background: #03a8e6;
  color: white;
  &:hover {
    background: #0e4a73;
    color: white;
  }
  &:focus {
    outline: none;
    border: 1px solid #097ab8;
  }
`;

export const CancelButton = styled.button`
  font-size: 1rem;
  padding: 5px 10px !important;
  border-radius: 5px;
  border: 1px solid #0e4a73;
  background-color: ${Themes.scribi.cancel} !important;
  color: white;
  &:hover {
    background-color: ${Themes.scribi.cancelDark} !important;
    color: white;
  }
  &:focus {
    outline: none;
    border: 1px solid #097ab8;
  }
`;

export const ExitButton = styled.button`
  font-size: 1rem;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 1.1rem;
  width: 1.1rem;
  border: none;
  background-color: ${Themes.scribi.cancel} !important;
  color: white;
  &:hover {
    background-color: ${Themes.scribi.cancelDark} !important;
    color: white;
  }
  &:focus {
    outline: none;
    border: 1px solid #097ab8;
  }
  svg {
    font-size: 0.5rem;
    height: 0.5rem;
    width: 0.5rem;
    fill: white;
    color: white;
  }
`;

export const ActionButton = styled.button`
  font-size: 1rem;
  padding: 2px 5px !important;
  border-radius: 5px;
  border: 1px solid #0e4a73;
  background: ${({ theme }) => theme.primary} !important;
  color: white;
  user-select: none;
  white-space: nowrap;
  &:hover {
    background: ${({ theme }) => theme.tertiary} !important;
    color: white;
  }
  &:focus {
    outline: none;
    border: 1px solid #097ab8;
  }
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
`;

export const MonthGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: transparent;
`;

export const MonthColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: transparent;
  div.weekRow {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
  }
  div.dayColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid black;
    padding: 5px;
    background-color: ${({ theme, calendarBoxColor }) =>
      calendarBoxColor === "today"
        ? theme.calendarPrimary
        : calendarBoxColor === "thisMonth"
        ? theme.calendarSecondary
        : theme.calendarTertiary};
  }
`;

export const MonthColumnWeekRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;

export const MonthColumnDayColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid black;
  padding: 5px;
  height: 100%;
  overflow-y: hidden;
  background-color: ${({ theme, calendarBoxColor }) =>
    calendarBoxColor === "today"
      ? theme.calendarPrimary
      : calendarBoxColor === "thisMonth"
      ? theme.calendarSecondary
      : theme.calendarTertiary};

  div.event-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
`;

export const CalendarEvent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  border: 1px solid black;
  padding: 5px;
  box-sizing: border-box;
  background-color: ${({ theme, event }) => theme[event]};
`;

export const CalendarHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: left;
  background-color: ${({ theme }) => theme.secondary};
  padding: 10px;
  button {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: white;
      font-size: 1rem;
    }
  }
  label {
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    user-select: none;
  }
  svg.divider {
    color: white;
    font-size: 1.2rem;
    height: 100%;
  }
`;
export const ProjectAreaHeader = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  background-color: ${({ theme }) => theme.secondary};
  padding: 10px;
  button {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: white;
      scale: ${({ selected }) => (selected ? "1.5" : "1")};
      font-size: 1.5rem;
    }
  }
  label {
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    user-select: none;
  }
  svg.divider {
    color: white;
    font-size: 1.2rem;
    height: 100%;
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  div.section-one {
    grid-column: 2;
    justify-content: flex-start;
  }
  div.section-two {
    grid-column: 3;
    justify-content: center;
  }
  div.section-three {
    grid-column: 4;
    justify-content: flex-end;
  }
  div.toolbar-section-one {
    grid-column: 2;
    justify-content: flex-start;
    gap: 10px;
    div.spacer {
      min-width: 5px;
      min-height: 10px;
      visibility: hidden;
    }
  }
  div.toolbar-section-two {
    grid-column: 3;
    justify-content: center;
    gap: 10px;
  }
  div.toolbar-section-three {
    grid-column: 4;
    justify-content: flex-end;
    gap: 10px;
  }
`;

export const CalendarButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    color: white;
    font-size: 1rem;
  }
  label {
    font-size: 1rem;
    color: white;
    font-weight: 500;
    cursor: pointer;
  }
`;

export const CalendarDaysContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.secondary};
  border-top: 1px solid slategray;
  overflow-y: ${({ weekView }) => (weekView ? "hidden" : "scroll")};
  div.day-row {
    label {
      color: white;
      font-size: 1rem;
      font-weight: 600;
      user-select: none;
      width: 100%;
      text-align: center;
    }
  }
  &::-webkit-scrollbar {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  scrollbar-color: transparent transparent;
`;

export const CalendarDayLabel = styled.label`
  color: white;
  background-color: ${({ theme, isToday }) =>
    isToday ? theme.darkHighlightColor : "transparent"};
`;

export const CalendarMonthDay = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 100%;
  border: 1px solid black;
  boxsizing: border-box;
  height: 100%;
  overflow-y: auto;
  background-color: ${({ theme, calendarBoxColor }) =>
    calendarBoxColor === "today"
      ? theme.calendarPrimary
      : calendarBoxColor === "thisMonth"
      ? theme.calendarSecondary
      : theme.calendarTertiary};
`;

export const CalendarWeekDay = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid black;
  boxsizing: border-box;
  height: 100%;
  position: relative;
  background-color: ${({ theme, calendarBoxColor }) =>
    calendarBoxColor === "today"
      ? theme.calendarPrimary
      : calendarBoxColor === "thisMonth"
      ? theme.calendarSecondary
      : theme.calendarTertiary};
`;

export const CalendarDayDay = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid black;
  boxsizing: border-box;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.calendarPrimary};
`;

export const TimeSlot = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.timeBorderColor}`};
  width: 100%;
`;

export const BodyContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: ${({ theme, dark }) =>
    dark ? theme.secondary : theme.bodyBackground};
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  div.editor-box {
    background-color: ${({ theme }) => theme.formBackground};
  }
`;

export const ModalOverlay = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: ${({ overlayStyle }) =>
    overlayStyle === "cover" ? "rgba(0,0,0,0.5)" : "transparent"};
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  div.modal {
    background: ${({ theme }) => theme.menuBackground};
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: ${({ position }) => position.y}px;
    left: ${({ position }) => position.x}px;
    div.menuOption {
      padding: 5px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      &:hover {
        background: ${({ theme }) => theme.menuHighlight};
        color: white;
      }
      svg {
        height: 15px;
        width: 15px;
      }
      label {
        white-space: nowrap;
        user-select: none;
        cursor: pointer;
      }
    }
  }
  div.form-modal {
    background: ${({ theme }) => theme.menuBackground};
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 500px;
    overflow-y: auto;
    top: 100px;
    margin: 0 auto;
  }
`;

export const DropArea = styled.div`
  border: 2px dashed #ccc;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  ${(props) => {
    if (props.orientation === "portrait") {
      return `
      width: 200px;
      height: 300px;
    `;
    }
    if (props.orientation === "landscape") {
      return `
      width: 300px;
      height: 200px;
    `;
    }
    if (props.orientation === "block") {
      return `
      width: 100%;
      height: 50px;
    `;
    }
    return "";
  }}
`;

export const SidebarContainer = styled(Section)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const SidebarBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
`;

export const SidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  padding: 10px;
`;

export function Tag({ tag, index, state, setState }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        padding: "5px",
        borderRadius: "5px",
        border: "1px solid black",
        whiteSpace: "nowrap",
      }}
    >
      {tag}
      <IconButton
        size={15}
        onClick={() => {
          const newTags = JSON.parse(JSON.stringify(state.tags));
          newTags.splice(index, 1);
          setState({ ...state, tags: newTags });
        }}
      >
        <Icons.DeleteIcon />
      </IconButton>
    </div>
  );
}

export const AutoTextArea = ({ value, setValue, button, placeholder }) => {
  const textAreaRef = useRef(null);

  const resize = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (textAreaRef.current) {
      // This will run once textAreaRef is mounted to the component
      resize();
    }
  }, [textAreaRef]);

  useEffect(() => {
    resize(); // Call the resize function whenever value changes
  }, [value]);

  return (
    <FormTextArea
      ref={textAreaRef}
      value={value}
      placeholder={placeholder ? placeholder : ""}
      onChange={(e) => {
        setValue(e.target.value);
        resize();
      }}
      onFocus={resize}
      onMouseOver={resize}
      style={{
        overflow: "hidden",
        resize: "none",
        paddingTop: button ? "20px" : "5px",
      }}
    />
  );
};

export const Body = styled(Container)`
  grid-row: 2;
  height: 100%;
  overflow: hidden;
  background-color: transparent;
`;

export const Nav = styled(Section)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const Main = styled(Section)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const Divider = styled(Bar)`
  background-color: lightgray;
  cursor: col-resize;
  max-width: 2px;
`;

export const ScribiLink = styled.a`
  color: ${({ theme }) => theme.formText};
  font-weight: 600;
`;

export const ScribiNavLink = styled(Link)`
  color: ${({ theme, $color }) => $color || theme.formText};
  font-weight: 600;
`;

export const FooterContainer = styled.div`
  background-color: ${({ theme }) => theme.primary};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  gap: 20px;
  label {
    font-size: 0.8rem;
  }
  a {
    font-size: 0.8rem;
  }
`;

export const ProjectCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  gap: 5px;
  background-color: ${({ theme }) => theme.secondary};
  cursor: pointer;
  width: 300px;
  height: 300px;
`;

export const ProjectCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const CustomEditorContent = styled(EditorContent)`
  height: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.formBackground};
  padding: 10px;
  display: flex;
  flex-direction: column;
  p {
    font-size: 1rem;
    font-weight: regular;
    font-family: ${({ font }) => (font ? font : "Arial")};
    line-height: ${({ lineheight }) => (lineheight ? lineheight : "1.2")};
    margin-bottom: ${({ paraspacing }) => (paraspacing ? paraspacing : "1")}em;
    text-indent: ${({ indent }) => (indent ? indent : "1.5")}em;
  }
  div.ProseMirror-focused {
    border: none;
    outline: none;
  }
  div.ProseMirror {
    height: 100%;
    background-color: ${({ theme }) => theme.formBackground};
  }
`;

export const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  gap: 10px;
`;

export const IconPreviewItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  svg {
    height: 25px;
    width: 25px;
  }
`;
