/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { db, RTDatabase } from "../../firestore/cloud-configs";
import {
  onSnapshot,
  collection,
  doc,
  orderBy,
  query,
  where,
  or,
} from "firebase/firestore";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTheme } from "styled-components";
import ScribiRestService from "../../firestore/ScribiRestService";
import {
  batchDeleteScribiDocuments,
  createScribiDocument,
  deleteScribiDocument,
  updateScribiDocument,
} from "../../firestore/db";
import { v4 as uuid } from "uuid";
import { onValue, ref, set, update } from "firebase/database";
import { useDndContext } from "@dnd-kit/core";
import { UserContext } from "./UserContext";
import { CatalogContext } from "./CatalogContext";
import { ModalContext } from "./ModalContext";

export const Scribi = React.createContext();

const ScribiProvider = ({ children }) => {
  const { user, setUser } = React.useContext(UserContext);
  const { notificationModal, setNotificationModal } =
    React.useContext(ModalContext);

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [showUserMenu, setShowUserMenu] = useState(false);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    menu: null,
  });
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false); 
  const id = searchParams.get("id");

  const paymentIds = [
    "",
    "price_1QGREGGNKV5mxzQahj4xnab4",
    "price_1QGREGGNKV5mxzQanPD1b1RN",
    "price_1QGREGGNKV5mxzQaGTNZsRtf",
    "price_1QGREGGNKV5mxzQaBDxmKp2o",
    "price_1QGREGGNKV5mxzQaIRUepKQ7",
    "price_1QGREGGNKV5mxzQaUbeFi3iP",
    "price_1QGREGGNKV5mxzQaYToZj0vc",
    "price_1QGREGGNKV5mxzQatIzOclCC",
    "price_1QGREGGNKV5mxzQa2RrN2C4N",
    "price_1QGREGGNKV5mxzQaP476yM4U",
    "price_1QGRBSGNKV5mxzQaR5udFpcT",
  ];
  // const paymentIds = [
  //   "",
  //   "price_1QGOMtGNKV5mxzQaMDUCXuYS",
  //   "price_1QGOMfGNKV5mxzQaRPYQrtzd",
  //   "price_1QGOMQGNKV5mxzQaHXVkUGaA",
  //   "price_1QGOMAGNKV5mxzQa0R3rGQhT",
  //   "price_1QGOM0GNKV5mxzQaCQjM8Mzb",
  //   "price_1QGOLnGNKV5mxzQa00CcCvdS",
  //   "price_1QGOLbGNKV5mxzQafcdunXvv",
  //   "price_1QGOLOGNKV5mxzQaRXH4WYGC",
  //   "price_1QGOLDGNKV5mxzQarYKwlkif",
  //   "price_1QGOKSGNKV5mxzQa2VMe3Amu",
  //   "price_1QGOJwGNKV5mxzQau5C3DvZe"
  // ]


  useEffect(() => {
    async function updateNotificationsSetting(value) {
      await updateScribiDocument({
        id: user.uid,
        notifications: value,
        doctype: "users",
        ownerId: user.uid,
      });
    }

    if (user && Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          updateNotificationsSetting(true);
        } else {
          // Handles both 'denied' and 'default'
          updateNotificationsSetting(false);
        }
      });
    }
  }, [user]);

  const [modal, setModal] = useState({
    visible: false,
    message: "",
    title: "",
    confirm: null,
  });

  const [formModal, setFormModal] = useState({
    component: null,
    visible: false,
  });

  const clearContext = () => {
    setUser(null);
    setShowUserMenu(false);
    setModalPosition({ x: 0, y: 0 });
  };

  return (
    <Scribi.Provider
      value={{
        loading, 
        setLoading,
        user,
        setUser,
        navigate,
        showUserMenu,
        setShowUserMenu,
        modalPosition,
        setModalPosition,
        clearContext,
        modal,
        setModal,
        location,
        searchParams,
        id,
        contextMenu,
        setContextMenu,
        formModal,
        setFormModal,
        paymentIds,
      }}
    >
      {children}
    </Scribi.Provider>
  );
};

export default ScribiProvider;
