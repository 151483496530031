import React from "react";
import {
  FooterContainer,
  ScribiLink,
  ScribiNavLink,
} from "../Scribi-Components/ScribiComponents";

const Footer = () => {
  return (
    <FooterContainer>
      <label>Scribi is copyright 2023 Scribi LLC and Brian Palmer</label>
      <ScribiNavLink style={{ marginLeft: "auto" }} to="/privacy">
        Privacy
      </ScribiNavLink>
      <ScribiNavLink to="/terms-and-conditions">
        Terms and Conditions
      </ScribiNavLink>
      <ScribiLink href="mailto:admin@scribi.app">Contact</ScribiLink>
    </FooterContainer>
  );
};

export default Footer;
