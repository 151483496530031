import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import styled from "styled-components";
import {
  ConfirmButton,
  TextInput,
} from "../Scribi-Components/ScribiComponents";

const BlockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 100vh;
  width: 100vw;
  color: #00aaff;
`;

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const DesktopAuth = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      // Sign in with Firebase auth (email/password)
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Get the Firebase token
      const token = await userCredential.user.getIdToken();

      // Redirect back to Electron app with the token in a deep link
      const redirectUri = `scribi://auth-callback?token=${encodeURIComponent(
        token
      )}`;
      window.location.href = redirectUri;
    } catch (error) {
      console.error("Authentication failed:", error);
      setError("Failed to sign in. Please check your credentials.");
    }
  };

  return (
    <BlockContainer>
      <AuthContainer>
        <h1>Sign In To Scribi</h1>
        <FormContainer onSubmit={handleLogin}>
          <TextInput
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextInput
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <ConfirmButton type="submit">Sign In</ConfirmButton>
        </FormContainer>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </AuthContainer>
    </BlockContainer>
  );
};

export default DesktopAuth;
