import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBookAtlas,
  faBooks,
  faCalendar,
  faCalendarDay,
  faCalendarDays,
  faCalendarWeek,
  faCalendarXmark,
  faChartMixed,
  faCircleQuestion,
  faCloudBolt,
  faFile,
  faFileExport,
  faFilePen,
  faGear,
  faGlobe,
  faHourglassClock,
  faHouse,
  faLightbulbOn,
  faListDots,
  faMessages,
  faPenNib,
  faPenNibSlash,
  faPenToSquare,
  faPipe,
  faPuzzle,
  faShelves,
  faSignOut,
  faSquareLeft,
  faSquareRight,
  faTypewriter,
  faUser,
  faUsers,
  faPlay,
  faPause,
  faStop,
  faListTree,
  faBoxArchive,
  faFiles,
  faFolder,
  faTrash,
  faNote,
  faDownload,
  faArrowRight,
  faArrowDown,
  faBrain,
  faShapes,
  faDatabase,
  faClock,
  faPuzzlePiece,
  faTools,
  faComments,
  faLanguage,
  faChartUser,
  faChartNetwork,
  faUpload,
  faFileDashedLine,
  faCodeBranch,
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faBars,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faXmark,
  faRotateRight,
  faUserPlus,
  faThumbtack,
  faBold,
  faItalic,
  faUnderline,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faAlignJustify,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const ScribiIcon = styled(FontAwesomeIcon)`
  fill: ${({ theme }) => theme.iconColor};
`;

const MenuIcon = () => {
  return <ScribiIcon icon={faBars} />;
};

const BookIcon = () => {
  return <ScribiIcon icon={faBook} />;
};

const PenIcon = () => {
  return <ScribiIcon icon={faPenNib} />;
};

const WorldIcon = () => {
  return <ScribiIcon icon={faGlobe} />;
};

const SeriesIcon = () => {
  return <ScribiIcon icon={faBooks} />;
};

const HomeIcon = () => {
  return <ScribiIcon icon={faHouse} />;
};

const ToggleOpenIconClosed = ({ open }) => {
  return <ScribiIcon icon={faChevronRight} />;
};

const ToggleOpenIconOpened = () => {
  return <ScribiIcon icon={faChevronDown} />;
};

const CalendarIcon = () => {
  return <ScribiIcon icon={faCalendar} />;
};

const ProjectsIcon = () => {
  return <ScribiIcon icon={faFilePen} />;
};

const IdeaIcon = () => {
  return <ScribiIcon icon={faLightbulbOn} />;
};

const CatalogIcon = () => {
  return <ScribiIcon icon={faShelves} />;
};

const ExportIcon = () => {
  return <ScribiIcon icon={faFileExport} />;
};

const TemplatesIcon = () => {
  return <ScribiIcon icon={faPuzzle} />;
};

const SocialIcon = () => {
  return <ScribiIcon icon={faUsers} />;
};

const MessagesIcon = () => {
  return <ScribiIcon icon={faMessages} />;
};

const TimerIcon = () => {
  return <ScribiIcon icon={faHourglassClock} />;
};

const ChevronLeftIcon = () => {
  return <ScribiIcon icon={faChevronLeft} />;
};

const ChevronRightIcon = () => {
  return <ScribiIcon icon={faChevronRight} />;
};

const VerticalDivider = () => {
  return <ScribiIcon icon={faPipe} />;
};

const CalendarDayIcon = () => {
  return <ScribiIcon icon={faCalendarDay} />;
};
const CalendarWeekIcon = () => {
  return <ScribiIcon icon={faCalendarWeek} />;
};
const CalendarMonthIcon = () => {
  return <ScribiIcon icon={faCalendarDays} />;
};
const CalendarTodayIcon = () => {
  return <ScribiIcon icon={faCalendarXmark} />;
};

const LogOutIcon = () => {
  return <ScribiIcon icon={faSignOut} />;
};

const CloseIcon = () => {
  return <ScribiIcon icon={faXmark} />;
};

const SettingsIcon = () => {
  return <ScribiIcon icon={faGear} />;
};

const UserIcon = () => {
  return <ScribiIcon icon={faUser} />;
};

const DeleteIcon = () => {
  return <ScribiIcon icon={faXmark} />;
};

const AddIcon = () => {
  return <ScribiIcon icon={faPlus} />;
};

const ArrowHideRight = () => {
  return <ScribiIcon icon={faSquareRight} />;
};

const ArrowHideLeft = () => {
  return <ScribiIcon icon={faSquareLeft} />;
};

const SupportIcon = () => {
  return <ScribiIcon icon={faCircleQuestion} />;
};

const EditIcon = () => {
  return <ScribiIcon icon={faPenToSquare} />;
};

const DocumentIcon = () => {
  return <ScribiIcon icon={faFile} />;
};

const BeatsheetIcon = () => {
  return <ScribiIcon icon={faChartMixed} />;
};

const OutlineIcon = () => {
  return <ScribiIcon icon={faListDots} />;
};

const BrainstormIcon = () => {
  return <ScribiIcon icon={faCloudBolt} />;
};

const ManuscriptIcon = () => {
  return <ScribiIcon icon={faTypewriter} />;
};

const StorybaseIcon = () => {
  return <ScribiIcon icon={faBookAtlas} />;
};

const PlayIcon = () => {
  return <ScribiIcon icon={faPlay} />;
};

const PauseIcon = () => {
  return <ScribiIcon icon={faPause} />;
};

const StopIcon = () => {
  return <ScribiIcon icon={faStop} />;
};

const ResetIcon = () => {
  return <ScribiIcon icon={faRotateRight} />;
};

const AddElement = () => {
  return <ScribiIcon icon={faUserPlus} />;
};

const AddSubtype = () => {
  return <ScribiIcon icon={faListTree} />;
};

const AddType = () => {
  return <ScribiIcon icon={faBoxArchive} />;
};

const PinIcon = () => {
  return <ScribiIcon icon={faThumbtack} />;
};

const Bold = () => {
  return <ScribiIcon icon={faBold} />;
};

const Italic = () => {
  return <ScribiIcon icon={faItalic} />;
};

const Underline = () => {
  return <ScribiIcon icon={faUnderline} />;
};

const AlignLeft = () => {
  return <ScribiIcon icon={faAlignLeft} />;
};

const AlignCenter = () => {
  return <ScribiIcon icon={faAlignCenter} />;
};

const AlignRight = () => {
  return <ScribiIcon icon={faAlignRight} />;
};

const AlignJustify = () => {
  return <ScribiIcon icon={faAlignJustify} />;
};

const ChapterIcon = () => {
  return <ScribiIcon icon={faFiles} />;
};

const PartIcon = () => {
  return <ScribiIcon icon={faFolder} />;
};

const TrashIcon = () => {
  return <ScribiIcon icon={faTrash} />;
};

const NoteIcon = () => {
  return <ScribiIcon icon={faNote} />;
};

const DownloadIcon = () => {
  return <ScribiIcon icon={faDownload} />;
};

const ArrowRight = () => {
  return <ScribiIcon icon={faArrowRight} />;
};

const ArrowDown = () => {
  return <ScribiIcon icon={faArrowDown} />;
};

const Brain = () => {
  return <ScribiIcon icon={faBrain} />;
};

const FileExport = () => {
  return <ScribiIcon icon={faFileExport} />;
};

const Book = () => {
  return <ScribiIcon icon={faBook} />;
};

const Shapes = () => {
  return <ScribiIcon icon={faShapes} />;
};

const Database = () => {
  return <ScribiIcon icon={faDatabase} />;
};

const RegClock = () => {
  return <ScribiIcon icon={faClock} />;
};

const PuzzlePiece = () => {
  return <ScribiIcon icon={faPuzzlePiece} />;
};

const Tools = () => {
  return <ScribiIcon icon={faTools} />;
};

const Language = () => {
  return <ScribiIcon icon={faLanguage} />;
}

const Productivity = () => {
  return <ScribiIcon icon={faChartUser} />;
}

const Network = () => {
  return <ScribiIcon icon={faChartNetwork} />;
}

const Pipeline = () => {
  return <ScribiIcon icon={faUpload} />;
}

const BookAtlas = () => {
  return <ScribiIcon icon={faBookAtlas} />;
}

const FileDashedLine = () => {
  return <ScribiIcon icon={faFileDashedLine} />;
}

const Code = () => {
  return <ScribiIcon icon={faCodeBranch} />;
}

const Icons = {
  Code,
  FileDashedLine,
  BookAtlas,
  Pipeline,
  Network,
  Productivity,
  Language,
  Tools,
  Brain,
  FileExport,
  Book,
  Shapes,
  Database,
  RegClock,
  PuzzlePiece,
  ArrowDown,
  ArrowRight,
  DownloadIcon,
  BookIcon,
  MenuIcon,
  PenIcon,
  WorldIcon,
  SeriesIcon,
  HomeIcon,
  CalendarIcon,
  ProjectsIcon,
  IdeaIcon,
  ToggleOpenIconClosed,
  ToggleOpenIconOpened,
  CatalogIcon,
  ExportIcon,
  TemplatesIcon,
  SocialIcon,
  MessagesIcon,
  TimerIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  VerticalDivider,
  CalendarDayIcon,
  CalendarWeekIcon,
  CalendarMonthIcon,
  CalendarTodayIcon,
  LogOutIcon,
  CloseIcon,
  SettingsIcon,
  UserIcon,
  DeleteIcon,
  AddIcon,
  ArrowHideRight,
  ArrowHideLeft,
  SupportIcon,
  EditIcon,
  DocumentIcon,
  BeatsheetIcon,
  OutlineIcon,
  BrainstormIcon,
  ManuscriptIcon,
  StorybaseIcon,
  PlayIcon,
  PauseIcon,
  StopIcon,
  ResetIcon,
  AddElement,
  AddSubtype,
  AddType,
  PinIcon,
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  ChapterIcon,
  PartIcon,
  TrashIcon,
  NoteIcon,
};

export default Icons;
