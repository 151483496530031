import React from "react";
import JumboSplash from "./Splash/JumboSplash";
import styled from "styled-components";
import Icons from "./Icons/Icons";
import { Container, Row, Col, Card } from "react-bootstrap";
import { ScribiNavLink } from "./Scribi-Components/ScribiComponents";

const FeatureDisplayContainer = styled(Container)`
  padding: 4rem 0;
  color: #060606;

  h2 {
    margin-bottom: 3rem;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .feature-section {
    margin-bottom: 4rem;
  }

  .feature-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 3rem;
    color: #0e4a73;
    margin-bottom: 1rem;
  }

  .feature-card {
    border: none;
    background-color: #f9f9f9;
    text-align: center;
    height: 100%;
    p {
      margin-bottom: 0.5rem;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .feature-card .card-body {
    padding: 2rem 1rem;
  }

  .card-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }

  .card-text {
    font-size: 1rem;
    color: #555;
  }
`;

const ButtonBase = styled(ScribiNavLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
`;

const PrimaryButton = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5e7eb;
  color: #0e4a73;
  border: none;
  width: auto;
  &:hover {
    background-color: #e5e7eb;
  }
`;

const Roadmap = () => {
  return (
    <>
      <JumboSplash />
      <FeatureDisplayContainer>
        <h2>The Road Ahead</h2>
        <p
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            textAlign: "center",
            fontSize: "1.1rem",
            color: "#555",
            marginBottom: "0.5rem",
          }}
        >
          Scribi will be in constant development for the foreseeable future. On
          top of the current suite of awesome features, here's what's in store,
          and for the skeptics among us, a few hints about what makes these
          realistic goals. Part of what makes a lot of this possible is how
          Scribi has been engineered from day one. Well, more like day 700 and
          something, but when I started out this project I made a dream list of
          every conceivable feature I would want in my software. No holds
          barred, no idea too wild or fantastical. Everything I could think up;
          and it was quite a lot.
        </p>
        <p
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            textAlign: "center",
            fontSize: "1.1rem",
            color: "#555",
            marginBottom: "2rem",
          }}
        >
          But since I was both the engineer and the dev team in one, I had the
          luxury of setting the software up ahead of time to incorporate all of
          those features. It took a number of iterations, for sure, and probably
          delayed development at times, but I've managed to put together
          something modular enough that when it's time to add new features they
          are guaranteed not to break anything. Modules are entirely isolated
          from one another and hook into the central API for the software, which
          is technical jargon to say that new features can be plugged right in
          without much fuss. In fact, down at the far end of the roadmap, I've
          got plans for opening up that API so that enterprising users will even
          be able to develop their own custom modules. Take a look!
        </p>
        <div className="feature-section">
          <Row className="g-2">
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.Productivity />
                  </div>
                  <Card.Title>Productivity Tracking</Card.Title>
                  <Card.Text>
                    Productivity tracking and feedback is already mostly done,
                    but won't be finished and rolled out until after release. As
                    soon as Scribi goes live and you start working, it'll start
                    collecting data about how much you write and when, and for
                    how long in a session, and so on. And when I say that Scribi
                    is tracking that, I mean it's collecting data locally and
                    storing it in your encrypted database, which is encrypted
                    with a user-specific secret encryption key tied to your
                    account serial but stored away from Scribi's servers in a
                    secure key repository. Don't worry, I don't want or need your
                    data and have no intention of ever collecting anything on
                    Scribi's backend. But soon after launch, you'll be able to
                    see those metrics to determine when you're most productive.
                    Sure, you set aside a whole day for writing—but do you really
                    use the whole day, or are you typically a morning writer? Or
                    an evening writer? Maybe you're most productive right before
                    bed, or at three in the morning on weekends. Who knows?
                    Scribi will, because it'll be tracking these things so that
                    you can see how you actually work, and then plan accordingly.
                  </Card.Text>
                  <Card.Text>
                    Then a bit later, when you start a new project, Scribi will
                    be able to recommend a realistic schedule and set up events
                    in your chosen calendar app to help keep you on track for
                    your deadlines. I've got a few ideas for some extra bells and
                    whistles there as well, from the incorporation of the mobile
                    companion app that can send you customized reminders about
                    what you're working on, how far along you are, and what needs
                    to be accomplished for the day, to hooking into AI tools that
                    can analyze your productivity metrics intelligently and help
                    you work smarter and in tune with your goals and rhythms. Of
                    course, like everything else in Scribi, this will be a
                    modular kind of system where you can use what you need and
                    switch off what you don't. Maybe you work best without any
                    pressure or scheduling, but my brain needs a lot of support
                    to keep me on track!
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.Network />
                  </div>
                  <Card.Title>Scribi Plus and Pro</Card.Title>
                  <Card.Text>
                    Don't worry, Scribi is a full-featured desktop application.
                    Nothing is locked; everything works out of the box. However,
                    things like keeping data synced between devices or utilizing
                    companion apps for your phone, smartwatch, and tablet, or
                    leveraging any kind of artificial intelligence via API,
                    whether that's ChatGPT or our own customized LLM instances—
                    it all has overhead costs beyond the purchase of a license.
                    Scribi Plus will be a $5/month subscription that allows you
                    to use Scribi's server as your backup and sync source to keep
                    devices all on the same page and enable things like live
                    collaboration and the use of the companion mobile apps. What
                    mobile apps? The ones currently in parallel development.
                    They'll let you quickly jot down notes, get scheduling
                    notifications to your phone or smartwatch—if you're as ADHD
                    as I am, it's helpful to have your phone reminding you what
                    you need to be doing, and I'm designing an ADHD mode for
                    notifications just for us neuro-spicy folks—or even just
                    grabbing links to books in your catalog or saved social media
                    posts to use when you need them.
                  </Card.Text>
                  <Card.Text>
                    Scribi Pro will be a bit more at $25/month and include all of
                    that as well as access to the APIs for both Scribi-specific
                    and other APIs for AI assistance. It's not my intention to
                    ever enable generative AI for writing books in Scribi,
                    especially since I got my start in steamy romance and, well,
                    most of the APIs would cancel my endpoint if I tried to use
                    them for that; but also because the whole point of Scribi is
                    to bring some of the joy and excitement back to the writing
                    process. That said, AI can be great for helping you throw
                    together a brainstorming session, or helping you flesh out
                    plot details in your outline, or even offering some guidance
                    around the use of different beat sheet structures. There are
                    any number of ways to leverage AI in ways that can accelerate
                    and support your process rather than replacing it.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.Language />
                  </div>
                  <Card.Title>The Conlang Module</Card.Title>
                  <Card.Text>
                    If you're one of those obsessive fantasy world-builder nerds
                    like me, you'll love this module. It'll roll out in two
                    stages. Stage one is purely mechanical and algorithmic. I've
                    broken down languages into a series of choices around
                    sentence structures and grammar rules found in human
                    languages. Each of those rules is associated with a series of
                    linguistic transformations. You'll make your selections,
                    answer some initial questions about suffixes or prefixes or
                    pronouns, etc., depending on your choices, and Scribi will
                    use those choices to translate English, initially, into your
                    constructed language. When it doesn't know a word, it will
                    prompt you for a root word and add it to the dictionary. This
                    way, your language grows organically. Or, you can spend all
                    the time you like providing vocabulary inside the module.
                  </Card.Text>
                  <Card.Text>
                    Stage two is even more ambitious. Eventually, Scribi Plus and
                    Pro will launch. Scribi Pro will give access to customized AI
                    tools, one of which will be a language transformer that takes
                    your grammar rules and vocabulary database and will translate
                    text even more accurately, and allow for things that are
                    difficult to capture in an algorithm, like idiomatic
                    expressions. An AI-driven system will even be able to take
                    your root words and recommend new vocabulary based on the
                    work you've already done. This will require a custom-trained
                    LLM, but the required model is already identified.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.Pipeline />
                  </div>
                  <Card.Title>Publishing Pipelines</Card.Title>
                  <Card.Text>
                    Scribi's book block system will already completely
                    revolutionize the management of large catalogs, and the
                    process of throwing together a box set, omnibus, or sample
                    pack into an eBook that's ready to go immediately. I'd really
                    like to take that a step further, though, and allow users to
                    log directly into sales channels from Scribi's API and funnel
                    your books directly into them. I've approached some of the
                    big dogs about this, and the response has not been to
                    immediately shut the idea down, which is progress! They want
                    to see the software released and functioning in the wild
                    before they'll entertain those ideas. But just imagine
                    deciding to update the Also By page in the back of your books
                    to include your most recent releases—for a catalog of, say,
                    fifty standalone romance novels—and being able to click a
                    button and immediately push those files out through the
                    pipeline to whatever marketplaces you're on. Me, personally?
                    I <em>hate</em> busy work like that, and the bigger my
                    catalog gets the more I loathe doing any catalog-wide
                    updates.
                  </Card.Text>
                  <Card.Text>
                    The scaffolding for that feature is already in Scribi
                    currently! It's just kind of like latent, inactive DNA until
                    it has an API endpoint to reach out to. So hopefully that's a
                    feature that can roll out sooner rather than later, but that
                    will depend a bit on those other markets like Amazon, Apple
                    iBooks, Kobo, and even Draft2Digital, which could simplify a
                    lot of the process of publishing. Hey, if you end up loving
                    Scribi as much as I do, you might send a tweet or an email to
                    your favorite sales channel and let them know you'd like to
                    see Scribi's pipeline integrated with their platform. That
                    kind of thing can really help move the needle on these kinds
                    of features.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.BookAtlas />
                  </div>
                  <Card.Title>Author Wikis and Landing Pages</Card.Title>
                  <Card.Text>
                    As the desktop application matures, the online platform will
                    expand. One of the benefits of having your entire catalog in
                    a database is that the things you can do with all of that
                    information are practically endless. For example, how cool
                    would it be to flush all the characters, locations, items,
                    relationships, and notes in your storybase out to a public
                    Wiki page for fans to enjoy? Or take your conlang and
                    auto-generate a guide for the superfans you know speak
                    Elvish, Klingon, and Dothraki already? What if you had an
                    author page that automatically updated itself each time you
                    mark a book as published, and a newsletter call to action
                    that updated when you realize you need to upgrade from the
                    budget option to a professional-level platform now that
                    you're sending emails out to five thousand readers every
                    week?
                  </Card.Text>
                  <Card.Text>
                    Bringing Scribi online like this will open up all sorts of
                    possibilities for new features that can not only help you
                    produce books, but build a platform as well. Integrations
                    into social media are already planned, so that you can
                    quickly turn an excerpt and an image into a Facebook post.
                    Tweeting to X isn't free via their API, so that'll have to be
                    a Plus or Pro feature depending on where they take their API,
                    but you get the picture. Integrations with social media are
                    definitely on my radar for the future as a must-have, because
                    I would really like to centralize my entire career management
                    task list in one application. Maybe that would be great for
                    you too!
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.Shapes />
                  </div>
                  <Card.Title>
                    And from there, Social Media and Newsletter Templates!
                  </Card.Title>
                  <Card.Text>
                    Part of the reason I got so excited about Scribi's template
                    system in the first place was to save me time reinventing the
                    wheel every time I open up a new document in a certain text
                    editor to fit the way I work. But templates can be used in
                    all sorts of fun ways. Even some of the popular formats for
                    TikTok can be boiled down to templates involving images,
                    videos, and text. So I fully intend to expand the template
                    feature to work with other mediums. Once we're hooked into
                    the various newsletter APIs, you'll be able to grab text from
                    your work in progress, pop it into your newsletter template,
                    and send it right along, all without having to leave Scribi.
                    Same goes for tweets, threads, Facebook posts, and Instagram
                    posts. Basically anything I can hook into will have some kind
                    of useful template system associated. And just due to the
                    nature of how templates are structured internally, you'll
                    always be able to export and import custom templates well
                    beyond what ends up in the Scribi resource library.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.FileDashedLine />
                  </div>
                  <Card.Title>New Editor Format Presets</Card.Title>
                  <Card.Text>
                    So some of this is already in the works, but I back-burnered
                    implementation until after release so that I could keep
                    focused on getting Scribi actually out to you all. But
                    basically I've got different presets for formatting available
                    in the text editor. If you want to write a screenplay, you'll
                    switch the preset from prose to screenplay and Scribi will
                    handle the rest, making sure that everything is to industry
                    standard. My husband happens to be a playwright, so I
                    included a preset for that as well, and then figured why stop
                    there? So I have the setup for a Choose Your Own
                    Adventure-style book that allows you to style and embed links
                    at the bottom of your scenes that, internally, link to other
                    scenes. When exported to eBook you'll get hyperlink buttons
                    that let the reader navigate through the story, and in a
                    print version you'll get page numbers instead.
                  </Card.Text>
                  <Card.Text>
                    Other types of formats could be on the horizon once I've got
                    things covered for us fiction writers, but I do want to stay
                    focused on prose-oriented writing for a good long while,
                    until we've got plenty of excellent and stable features built
                    out. Once it seems like that's squared away, I'll expand into
                    more specific formats like TTRPG source books, and probably
                    things like graphic novels as well. Basically I'd like Scribi
                    to eventually handle anything that any kind of independently
                    publishing artist would need. Some of those formats are
                    closer in than others.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.Code />
                  </div>
                  <Card.Title>The Open Source API</Card.Title>
                  <Card.Text>
                    Once everything I've dreamt up is running as part of the core
                    software, I'll eventually open up the API for Scribi so that
                    other developer-authors like me—probably a somewhat niche
                    configuration, admittedly—can start building their own custom
                    modules to interact with Scribi's core functions. If you want
                    to expand the capabilities of the calendar and timeline
                    system, you'll be able to design a module that can make it
                    work a little differently, add new elements, maybe even
                    customize aspects of the database schema; though that'll have
                    to be a carefully managed implementation.
                  </Card.Text>
                  <Card.Text>
                    Once we hit that stage, the sky is literally the limit on
                    what Scribi can be capable of! And that could potentially
                    lead to a secondary module market as well. That's a ways off,
                    of course, but it's all just to say that my plans for Scribi
                    reach far and wide, and I have no intention of cutting this
                    road short.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col md={4}>
                  <Card className="feature-card h-100">
                    <Card.Body>
                      <Card.Title>Title</Card.Title>
                      <Card.Text>
                       Text
                      </Card.Text>
                     <Card.Text>
                        Text
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col> */}
          </Row>
        </div>
        <p
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            textAlign: "center",
            fontSize: "1.1rem",
            color: "#555",
          }}
        >
          Excited for Scribi? Don't forget you get a 14-day free trial, and you
          can split your purchase up over 12 months.
        </p>
        <PrimaryButton to="/download">Get Scribi!</PrimaryButton>
      </FeatureDisplayContainer>
    </>
  );
};

export default Roadmap;