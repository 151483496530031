import React from "react";
import { Scribi } from "./store";
import { useDndContext, useDndMonitor } from "@dnd-kit/core";
import { updateScribiDocument } from "../../firestore/db";

export const DragHandleContext = React.createContext();

const DragHandleManager = ({ children }) => {
  const { project, elements, setModal } = React.useContext(Scribi);

  const { active, over } = useDndContext();

  const spliceOutByIndexMap = [
    // Handling an indexMap with length 1
    (arr, indexMap) => {
      const removedItem = arr.splice(indexMap[0], 1)[0];
      return { updatedArray: arr, removedItem };
    },
    // Handling an indexMap with length 2
    (arr, indexMap) => {
      const removedItem = arr[indexMap[0]].children.splice(indexMap[1], 1)[0];
      return { updatedArray: arr, removedItem };
    },
    // Handling an indexMap with length 3
    (arr, indexMap) => {
      const removedItem = arr[indexMap[0]].children[
        indexMap[1]
      ].children.splice(indexMap[2], 1)[0];
      return { updatedArray: arr, removedItem };
    },
  ];

  const insertByIndexMap = [
    // Handling an indexMap with length 1
    (arr, indexMap, objToInsert) => {
      if (!arr[indexMap[0]].children) {
        arr[indexMap[0]].children = [];
      }
      arr[indexMap[0]].children.push(objToInsert);
      return arr;
    },
    // Handling an indexMap with length 2
    (arr, indexMap, objToInsert) => {
      if (!arr[indexMap[0]].children[indexMap[1]].children) {
        arr[indexMap[0]].children[indexMap[1]].children = [];
      }
      arr[indexMap[0]].children[indexMap[1]].children.push(objToInsert);
      return arr;
    },
    // Handling an indexMap with length 3
    (arr, indexMap, objToInsert) => {
      if (
        !arr[indexMap[0]].children[indexMap[1]].children[indexMap[2]].children
      ) {
        arr[indexMap[0]].children[indexMap[1]].children[indexMap[2]].children =
          [];
      }
      arr[indexMap[0]].children[indexMap[1]].children[
        indexMap[2]
      ].children.push(objToInsert);
      return arr;
    },
  ];

  const insertByDividerIndexMap = [
    // Handling an indexMap with length 1
    (arr, indexMap, objToInsert) => {
      arr.splice(indexMap[0], 0, objToInsert);
      return arr;
    },
    // Handling an indexMap with length 2
    (arr, indexMap, objToInsert) => {
      //check if the object at indexMap[0] has children, if not, add an empty children array:
      if (!arr[indexMap[0]].children) {
        arr[indexMap[0]].children = [];
      }
      arr[indexMap[0]].children.splice(indexMap[1], 0, objToInsert);
      return arr;
    },
    // Handling an indexMap with length 3
    (arr, indexMap, objToInsert) => {
      if (!arr[indexMap[0]].children[indexMap[1]].children) {
        arr[indexMap[0]].children[indexMap[1]].children = [];
      }
      arr[indexMap[0]].children[indexMap[1]].children.splice(
        indexMap[2],
        0,
        objToInsert
      );
      return arr;
    },
  ];

  const handleManuscriptDragEnd = async (event) => {
    const msParts = ["document", "chapter", "part"];
    if (
      event.over?.data?.current?.reject?.includes(
        event.active.data.current.type
      ) ||
      !event.over
    )
      return;
    if (msParts.includes(event.active.data.current.type)) {
      let newContents = JSON.parse(JSON.stringify(project.contents));
      let itemIndexMap = event.active.data.current.indexMap;
      let targetIndexMap = event.over.data.current.indexMap;

      if (event.over.data.current.type !== "drop-divider") {
        let stepOne = spliceOutByIndexMap[itemIndexMap.length - 1](
          newContents,
          itemIndexMap
        );
        newContents = insertByIndexMap[targetIndexMap.length - 1](
          stepOne.updatedArray,
          targetIndexMap,
          stepOne.removedItem
        );
      } else if (event.over.data.current.type === "drop-divider") {
        let stepOne = spliceOutByIndexMap[itemIndexMap.length - 1](
          newContents,
          itemIndexMap
        );

        console.log("Step one: ", stepOne);

        console.log("Target index map: ", targetIndexMap);

        newContents = insertByDividerIndexMap[targetIndexMap.length - 1](
          stepOne.updatedArray,
          targetIndexMap,
          stepOne.removedItem
        );
      }

      // Perform your update operation with newContents
      await updateScribiDocument({ ...project, contents: newContents });
    }
  };

  const handleStorybaseDragEnd = async (event) => {
    let newType = event.over.data.current.schemaType;
    let newSubType = event.over.data.current.schemaSubType || null;
    let updatedElement = JSON.parse(
      JSON.stringify(event.active.data.current.element)
    );

    updatedElement.type = newType;
    updatedElement.subtype = newSubType;

    try {
      await updateScribiDocument(updatedElement);
    } catch (error) {
      setModal({
        visible: true,
        title: "Error",
        message: `There was an error moving this element: ${error.message}`,
        confirm: () =>
          setModal({
            visible: false,
            title: "",
            message: "",
            confirm: null,
            cancel: null,
          }),
      });
    }
  };

  useDndMonitor({
    onDragStart(event) {},
    onDragMove(event) {},
    onDragOver(event) {},
    onDragEnd(event) {
      if (!event.over) return;
      const over = event.over.data.current.context;
      const active = event.active.data.current.context;
      if (
        over === "manuscript-droppable" &&
        active === "manuscript-draggable"
      ) {
        handleManuscriptDragEnd(event);
      }
      if (over === "storybase-droppable" && active === "storybase-draggable") {
        handleStorybaseDragEnd(event);
      }
    },
    onDragCancel(event) {},
  });
  return (
    <DragHandleContext.Provider value={{ active }}>
      {children}
    </DragHandleContext.Provider>
  );
};

export default DragHandleManager;
