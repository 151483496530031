import React, { useContext } from "react";
import { Scribi } from "../utilities/api/store/store";
import styled from "styled-components";
import {
  CancelButton,
  ConfirmButton,
} from "../Scribi-Components/ScribiComponents";

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  position: relative;
  width: 400px;
  background-color: ${({ theme }) => theme.secondary};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.primary};
  box-shadow: 10 10 5px ${({ theme }) => theme.primary};
`;

const ModalTitle = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalBody = styled.div`
  position: relative;
  width: 100%;
`;

const ModalMessage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalButtons = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 0 0 5px 5px;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: auto;
`;

const MessageModal = () => {
  const { modal, setModal } = useContext(Scribi);
  return modal.visible ? (
    <ModalOverlay>
      <ModalContainer>
        <ModalTitle>
          <h3>{modal.title}</h3>
        </ModalTitle>
        <ModalBody>
          <ModalMessage>
            <p>{modal.message}</p>
          </ModalMessage>
        </ModalBody>
        <ModalButtons>
          <ConfirmButton
            onClick={() => {
              modal.confirm();
              setModal({
                visible: false,
                message: "",
                title: "",
                confirm: null,
              });
            }}
          >
            Okay
          </ConfirmButton>
          {modal.cancel && (
            <CancelButton
              onClick={() =>
                setModal({
                  visible: false,
                  message: "",
                  title: "",
                  confirm: null,
                })
              }
            >
              Cancel
            </CancelButton>
          )}
        </ModalButtons>
      </ModalContainer>
    </ModalOverlay>
  ) : null;
};

export default MessageModal;
