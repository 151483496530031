/* eslint-disable no-unused-vars */
import { auth } from "./cloud-configs";

const api = process.env.REACT_APP_CLOUD_FUNCTION_API_URL;

const checkDisplayNameAvailable = async (displayName) => {
  try {
    const response = await fetch(`${api}/userNameCheck`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ displayName }),
    });

    const data = await response.json();
    if (response.status === 200) {
      return data.isAvailable;
    } else {
      alert(`Error: ${data.message}`);
      throw new Error(data.message);
    }
  } catch (error) {
    alert(`Network Error: ${error.message}`);
    throw error;
  }
};

const createUserDoc = async (user, displayName) => {
  let token;
  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    alert(error.message);
    throw error;
  }

  try {
    const res = await fetch(`${api}/createUserDoc`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: user.uid, email: user.email, displayName }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };

      throw error;
    }

    return res.json();
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

const createStripeSession = async (priceId, mode, customer, meta) => {
  let token;
  let user;
  if (auth.currentUser) {
    user = auth.currentUser;
    try {
      token = await user.getIdToken();
    } catch (error) {
      alert(error.message);
      throw error;
    }

    try {
      const res = await fetch(`${api}/createCheckoutSession`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ priceId, user, mode, customer, meta }),
      });

      if (res.status !== 200) {
        const errorMessage = await res.text();
        const error = { message: errorMessage };

        throw error;
      }

      return res.json();
    } catch (error) {
      alert(error.message);
      throw error;
    }
  } else {
    try {
      const res = await fetch(`${api}/createCheckoutSession`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ priceId, user: null, mode }),
      });

      if (res.status !== 200) {
        const errorMessage = await res.text();
        const error = { message: errorMessage };

        throw error;
      }

      return res.json();
    } catch (error) {
      alert(error.message);
      throw error;
    }
  }
};

const checkUserDoc = async (userId, email) => {
  let token;
  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    alert(error.message);
    throw error;
  }

  try {
    const res = await fetch(`${api}/checkUserDoc`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-username": userId,
        "x-email": email,
      },
      body: JSON.stringify({ userId, email }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };

      throw error;
    }

    return res.json();
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

const advanceTestSubscription = async (subscription) => {
  let token;
  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    alert(error.message);
    throw error;
  }

  try {
    const res = await fetch(`${api}/advanceTestSubscription`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ subscription }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };

      throw error;
    }

    return res.json();
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

const cancelSubscription = async (subscription, serial) => {
  let token;
  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    alert(error.message);
    throw error;
  }

  try {
    const res = await fetch(`${api}/cancelSubscription`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-username": auth.currentUser.uid,
        "x-email": auth.currentUser.email,
        "x-serial": serial,
      },
      body: JSON.stringify({ subscription }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };

      throw error;
    }

    return res.json();
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

const upgradeSubscription = async (data, serial) => {
  let token;
  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    alert(error.message);
    throw error;
  }

  try {
    const res = await fetch(`${api}/upgradeSubscription`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-username": auth.currentUser.uid,
        "x-email": auth.currentUser.email,
        "x-serial": serial,
      },
      body: JSON.stringify({ ...data }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };

      throw error;
    }

    return res.json();
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

const downgradeSubscription = async (subscription, serial) => {
  let token;
  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    alert(error.message);
    throw error;
  }

  try {
    const res = await fetch(`${api}/downgradeSubscription`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-username": auth.currentUser.uid,
        "x-email": auth.currentUser.email,
        "x-serial": serial,
      },
      body: JSON.stringify({ subscription }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };

      throw error;
    }

    return res.json();
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

const getSubscriptionDetails = async (subscriptionId, serial) => {
  let token;

  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    alert(error.message);
    throw error;
  }

  try {
    const res = await fetch(`${api}/getSubscriptionDetails`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-username": auth.currentUser.uid,
        "x-email": auth.currentUser.email,
        "x-serial": serial,
      },
      body: JSON.stringify({ subscriptionId }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };
      throw error;
    }

    return res.json();
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

const submitMailingListSignup = async (email, firstname, lastname) => {
  try {
    const res = await fetch(`${api}/mailingListSignup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, firstname, lastname }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };
      throw error;
    }
    return await res.json();
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

const verifyRecaptcha = async (token) => {
  try {
    const res = await fetch(`${api}/verifyRecaptcha`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    });

    if (res.status !== 200) {
      const errorMessage = await res.text();
      const error = { message: errorMessage };
      throw error;
    }
    return await res.json();
  } catch (error) {
    alert(error.message);
    throw error;
  }
}

const ScribiRestService = {
  verifyRecaptcha,
  submitMailingListSignup,
  getSubscriptionDetails,
  cancelSubscription,
  upgradeSubscription,
  downgradeSubscription,
  advanceTestSubscription,
  checkUserDoc,
  createStripeSession,
  createUserDoc,
  checkDisplayNameAvailable,
};

export default ScribiRestService;
