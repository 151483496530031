const users = (data, uid) => {
  if (!data) return;
  if (!uid) return;
  if (!data.id) return;
  if (!(data.ownerId === uid)) return;

  let newData = {};

  if (data.id) {
    newData.id = data.id;
  }
  if (data.ownerId) {
    newData.ownerId = data.ownerId;
  }
  if (data.booksperyear) {
    newData.booksperyear = data.booksperyear;
  }
  if (data.displayName) {
    newData.displayName = data.displayName;
  }
  if (data.displayNameLowerCase) {
    newData.displayNameLowerCase = data.displayNameLowerCase;
  }
  if (data.photoURL) {
    newData.photoURL = data.photoURL;
  }
  if (data.theme) {
    newData.theme = data.theme;
  }
  if (data.bio) {
    newData.bio = data.bio;
  }
  if (data.dailywords) {
    newData.dailywords = data.dailywords;
  }
  if (data.friends) {
    newData.friends = data.friends;
  }
  if (data.groups) {
    newData.groups = data.groups;
  }
  if (data.doctype) {
    newData.doctype = data.doctype;
  }
  if (data.targetHandler) {
    newData.targetHandler = data.targetHandler;
  }
  if (data.tutorials) {
    newData.tutorials = data.tutorials;
  }
  if (data.notifications) {
    newData.notifications = data.notifications;
  }
  if (data.hours) {
    newData.hours = data.hours;
  }
  if (data.booksperyear) {
    newData.booksperyear = data.booksperyear;
  }

  return newData;
};
const pens = (data, uid) => {
  let newDoc = {};

  if (!data) return;
  if (!uid) return;
  if (!data.id) return;
  if (!(data.ownerId === uid || data.editors.includes(uid))) return;

  if (data.id) {
    newDoc.id = data.id;
  }
  if (data.ownerId) {
    newDoc.ownerId = data.ownerId;
  }
  if (data.firstName) {
    newDoc.firstName = data.firstName;
  }
  if (data.lastName) {
    newDoc.lastName = data.lastName;
  }
  if (data.fullName) {
    newDoc.fullName = data.fullName;
  }
  if (data.tags) {
    newDoc.tags = data.tags;
  }
  if (data.description) {
    newDoc.description = data.description;
  }
  if (data.authorBio) {
    newDoc.authorBio = data.authorBio;
  }
  if (data.authorPhoto) {
    newDoc.authorPhoto = data.authorPhoto;
  }
  if (data.authorLogo) {
    newDoc.authorLogo = data.authorLogo;
  }
  if (data.links) {
    newDoc.links = data.links;
  }
  if (data.public) {
    newDoc.public = data.public;
  }
  if (data.editors) {
    newDoc.editors = data.editors;
  }
  if (data.viewers) {
    newDoc.viewers = data.viewers;
  }
  if (data.doctype) {
    newDoc.doctype = "pens";
  }
  if (data.created) {
    newDoc.created = data.created;
  }

  return newDoc;
};
const worlds = (data, uid) => {
  let newDoc = {};

  if (!data) return;
  if (!uid) return;
  if (!data.id) return;
  if (!(data.ownerId === uid || data.editors.includes(uid))) return;

  if (data.id) {
    newDoc.id = data.id;
  }
  if (data.ownerId) {
    newDoc.ownerId = data.ownerId;
  }
  if (data.title) {
    newDoc.title = data.title;
  }
  if (data.plan) {
    newDoc.plan = data.plan;
  }
  if (data.description) {
    newDoc.description = data.description;
  }
  if (data.editors) {
    newDoc.editors = data.editors;
  }
  if (data.viewers) {
    newDoc.viewers = data.viewers;
  }
  if (data.public) {
    newDoc.public = data.public;
  }
  if (data.pen) {
    newDoc.pen = data.pen;
  }
  if (data.doctype) {
    newDoc.doctype = "worlds";
  }
  if (data.created) {
    newDoc.created = data.created;
  }
  if (data.tags) {
    newDoc.tags = data.tags;
  }
  if (data.schema) {
    newDoc.schema = data.schema;
  }
  if (data.notes) {
    newDoc.notes = data.notes;
  }
  if (data.logo) {
    newDoc.logo = data.logo;
  }

  return newDoc;
};
const series = (data, uid) => {
  let newDoc = {};

  if (!data) return;
  if (!uid) return;
  if (!data.id) return;
  if (!(data.ownerId === uid || data.editors.includes(uid))) return;

  if (data.id) {
    newDoc.id = data.id;
  }
  if (data.ownerId) {
    newDoc.ownerId = data.ownerId;
  }
  if (data.title) {
    newDoc.title = data.title;
  }
  if (data.plan) {
    newDoc.plan = data.plan;
  }
  if (data.description) {
    newDoc.description = data.description;
  }
  if (data.editors) {
    newDoc.editors = data.editors;
  }
  if (data.viewers) {
    newDoc.viewers = data.viewers;
  }
  if (data.public) {
    newDoc.public = data.public;
  }
  if (data.pen) {
    newDoc.pen = data.pen;
  }
  if (data.doctype) {
    newDoc.doctype = "series";
  }
  if (data.created) {
    newDoc.created = data.created;
  }
  if (data.tags) {
    newDoc.tags = data.tags;
  }
  if (data.notes) {
    newDoc.notes = data.notes;
  }
  if (data.logo) {
    newDoc.logo = data.logo;
  }
  if (data.world) {
    newDoc.world = data.world;
  }

  return newDoc;
};
const books = (data, uid) => {
  let newDoc = {};

  if (!data) return;
  if (!uid) return;
  if (!data.id) return;
  if (!(data.ownerId === uid || data.editors.includes(uid))) return;

  if (data.id) {
    newDoc.id = data.id;
  }
  if (data.ownerId) {
    newDoc.ownerId = data.ownerId;
  }
  if (data.title) {
    newDoc.title = data.title;
  }
  if (data.pen) {
    newDoc.pen = data.pen;
  }
  if (data.world) {
    newDoc.world = data.world;
  }
  if (data.series) {
    newDoc.series = data.series;
  }
  if (data.standalone) {
    newDoc.standalone = data.standalone;
  }
  if (data.description) {
    newDoc.description = data.description;
  }
  if (data.cover) {
    newDoc.cover = data.cover;
  }
  if (data.tags) {
    newDoc.tags = data.tags;
  }
  if (data.outline) {
    newDoc.outline = data.outline;
  }
  if (data.editors) {
    newDoc.editors = data.editors;
  }
  if (data.viewers) {
    newDoc.viewers = data.viewers;
  }
  if (data.notes) {
    newDoc.notes = data.notes;
  }
  if (data.contents) {
    newDoc.contents = data.contents;
  }
  if (data.isProject) {
    newDoc.isProject = data.isProject;
  }
  if (data.doctype) {
    newDoc.doctype = "books";
  }
  if (data.created) {
    newDoc.created = data.created;
  }
  if (data.targetWords !== undefined && data.targetWords !== null) {
    newDoc.targetWords = data.targetWords;
  }
  if (data.currentWords !== undefined && data.currentWords !== null) {
    newDoc.currentWords = data.currentWords;
  }
  return newDoc;
};
const elements = (data, uid) => {
  let newDoc = {};
  if (!data) return;
  if (!uid) return;
  if (!data.id) return;
  if (!(data.ownerId === uid || data.editors.includes(uid))) return;

  if (data.id) {
    newDoc.id = data.id;
  }
  if (data.ownerId) {
    newDoc.ownerId = data.ownerId;
  }
  if (data.world) {
    newDoc.world = data.world;
  }
  if (data.name) {
    newDoc.name = data.name;
  }
  if (data.description) {
    newDoc.description = data.description;
  }
  if (data.type) {
    newDoc.type = data.type;
  }
  if (data.subtype) {
    newDoc.subtype = data.subtype;
  } else {
    newDoc.subtype = null;
  }
  if (data.notes) {
    newDoc.notes = data.notes;
  }
  if (data.tags) {
    newDoc.tags = data.tags;
  }
  if (data.attributes) {
    newDoc.attributes = data.attributes;
  }
  if (data.images) {
    newDoc.images = data.images;
  }
  if (data.doctype) {
    newDoc.doctype = data.doctype;
  }
  if (data.created) {
    newDoc.created = data.created;
  }
  if (data.editors) {
    newDoc.editors = data.editors;
  }
  if (data.viewers) {
    newDoc.viewers = data.viewers;
  }
  if (data.pinned !== undefined && data.pinned !== null) {
    newDoc.pinned = data.pinned;
  }
  if(data.aliases){
    newDoc.aliases = data.aliases;
  }
  return newDoc;
};
const scenes = (data, uid) => {};
const notes = (data, uid) => {};
const calendarEvent = (data, uid) => {
  if (!data) return;
  if (!uid) return;
  if (!data.id) return;
  if (!data.ownerId) return;
  if (!(data.ownerId === uid || data.editors.includes(uid))) return;
  let newDoc = {};
  if (data.id) {
    newDoc.id = data.id;
  }
  if (data.ownerId) {
    newDoc.ownerId = data.ownerId;
  }
  if (data.sequenceId) {
    newDoc.sequenceId = data.sequenceId;
  }
  if (data.title) {
    newDoc.title = data.title;
  }
  if (data.start) {
    newDoc.start = new Date(data.start).getTime();
  }
  if (data.end) {
    newDoc.end = new Date(data.end).getTime();
  }
  if (data.allDay) {
    newDoc.allDay = data.allDay;
  }
  if (data.description) {
    newDoc.description = data.description;
  }
  if (data.series) {
    newDoc.series = data.series;
  }
  if (data.recurring) {
    newDoc.recurring = data.recurring;
  }
  if (data.period) {
    newDoc.period = data.period;
  }
  if (data.sequence) {
    newDoc.sequence = data.sequence;
  }
  if (data.interval) {
    newDoc.interval = data.interval;
  }
  if (data.link) {
    newDoc.link = data.link;
  }
  if (data.linkTitle) {
    newDoc.linkTitle = data.linkTitle;
  }
  if (data.priority) {
    newDoc.priority = data.priority;
  }
  if (data.reminder) {
    newDoc.reminder = data.reminder;
  }
  if (data.reminderDate) {
    newDoc.reminderDate = data.reminderDate;
  }
  if (data.bookId) {
    newDoc.bookId = data.bookId;
  }
  if (data.page) {
    newDoc.page = data.page;
  }
  if (data.doctype) {
    newDoc.doctype = data.doctype;
  }
  if (data.editors) {
    newDoc.editors = data.editors;
  }
  if (data.notes) {
    newDoc.notes = data.notes;
  }

  return newDoc;
};
const messages = (data, uid) => {};
const convos = (data, uid) => {};
const groups = (data, uid) => {};
const documents = (data, uid) => {
  let newDoc = {};
  if (!data) return;
  if (!uid) return;
  if (!data.id) return;
  if (!(data.ownerId === uid || data.editors.includes(uid))) return;
  if (data.id) {
    newDoc.id = data.id;
  }
  if (data.ownerId) {
    newDoc.ownerId = data.ownerId;
  }
  if (data.doctype) {
    newDoc.doctype = data.doctype;
  }
  if (data.editors) {
    newDoc.editors = data.editors;
  }
  if (data.notes) {
    newDoc.notes = data.notes;
  }
  if (data.viewers) {
    newDoc.viewers = data.viewers;
  }
  if (data.content) {
    newDoc.content = data.content;
  }
  if (data.words !== undefined && data.words !== null) {
    console.log("Sanitized words: ", data.words);
    newDoc.words = data.words;
  }

  return newDoc;
};
const timers = (data, uid, updateStat) => {
  if (!data) return;
  if (!uid) return;
  if (!data.id) return;
  if (!data.ownerId === uid) return;

  let newDoc = {};

  if (data.id) {
    newDoc.id = data.id;
  }
  if (data.title) {
    newDoc.title = data.title;
  }
  if (data.ownerId) {
    newDoc.ownerId = data.ownerId;
  }
  if (data.doctype) {
    newDoc.doctype = data.doctype;
  }
  if (data.group) {
    newDoc.group = data.group;
  }
  if (data.totalDuration !== undefined && data.totalDuration !== null) {
    newDoc.totalDuration = data.totalDuration;
  }
  if (data.status) {
    newDoc.status = data.status;
  }
  if (data.currentStep !== undefined && data.currentStep !== null) {
    newDoc.currentStep = data.currentStep;
  }
  if (data.timeElapsed !== undefined && data.timeElapsed !== null) {
    newDoc.timeElapsed = data.timeElapsed;
  }
  if (data.nextStepTime !== undefined && data.nextStepTime !== null) {
    newDoc.nextStepTime = data.nextStepTime;
  }
  if (data.schedule) {
    newDoc.schedule = data.schedule;
  }
  return newDoc;
};
const sprintStats = (data, uid) => {
  if (!data) return;
  if (!uid) return;
  if (!data.id) return;
  if (!data.ownerId === uid) return;

  let newDoc = {};

  if (data.id) {
    newDoc.id = data.id;
  }
  if (data.ownerId) {
    newDoc.ownerId = data.ownerId;
  }
  if (data.doctype) {
    newDoc.doctype = data.doctype;
  }
  if (data.stats) {
    newDoc.stats = data.stats;
  }

  return newDoc;
};

export default {
  users,
  pens,
  worlds,
  series,
  books,
  elements,
  scenes,
  notes,
  calendarEvent,
  messages,
  convos,
  groups,
  documents,
  timers,
  sprintStats,
};
