/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { AppContainer } from "./Scribi-Components/ScribiComponents";
import Header from "./Header/Header";
import { Scribi } from "./utilities/api/store/store";
import { auth, db } from "./utilities/firestore/cloud-configs";
import DesktopUserMenu from "./Menus/DesktopUserMenu";
import { ThemeProvider } from "styled-components";
import { Themes } from "./Scribi-Components/ScribiComponents";
import MessageModal from "./MessageModal/MessageModal";
import Policies from "./Policies/policies";
import { Route, Routes } from "react-router-dom";
import TOC from "./Policies/toc";
import Support from "./Support/Support";
import Footer from "./Footer/Footer";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import DragHandleManager from "./utilities/api/store/DragHandleManager";
import Notification from "./Notification Modal/Notification";
import DesktopAuth from "./AuthenticationBlock/DesktopAuth";
import Home from "./home";
import Features from "./features";
import Download from "./download";
import Roadmap from "./roadmap";
import About from "./About";
import AuthPage from "./AuthPage";
import Account from "./Account";
import { doc, onSnapshot } from "firebase/firestore";
import MailingList from "./MailingList";
import Loading from "./Loading";

const App = () => {
  const {
    showUserMenu,
    user,
    setUser,
    clearContext,
    setDragging,
    loading
  } = React.useContext(Scribi);


  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  useEffect(() => {
    let unsubscribe = null; // Prevents multiple subscriptions
  
    const handleAuthChange = (user) => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        unsubscribe = onSnapshot(userRef, (doc) => {
          if (doc.exists()) {
            setUser((prev) => ({ ...doc.data(), ...prev }));
          } else {
            setUser((prev) => ({ ...user }));
          }
        });
      } else {
        setUser(null);
        clearContext();
        // navigate('/')
      }
    };
  
    const unsubscribeAuth = auth.onAuthStateChanged(handleAuthChange);
  
    return () => {
      if (unsubscribe) unsubscribe(); // Unsubscribe from Firestore
      unsubscribeAuth(); // Unsubscribe from auth state listener
    };
  }, [setUser]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragStart={() => setDragging(true)}
      onDragEnd={() => setDragging(false)}
    >
      <ThemeProvider theme={Themes[user?.theme] || Themes.dark}>
        <DragHandleManager>
          <AppContainer>
             <Header />
            <div style={{ height: "100%", width: "100%", overflowY: "auto", paddingBottom: "1rem", boxSizing: "border-box", position: 'relative' }}>
              <Routes>
                <Route path="/privacy" element={<Policies />} />
                <Route path="/terms-and-conditions" element={<TOC />} />
                <Route path="/support" element={<Support />} />
                <Route path="/desktop-auth" element={<DesktopAuth />} />
                <Route path="/features" element={<Features />} />
                <Route path="/download" element={<Download />} />
                <Route path="/roadmap" element={<Roadmap />} />
                <Route path="/about" element={<About />} />
                <Route path="/login" element={<MailingList />} />
                {user && <Route path="/account" element={<Account />} />}
                <Route path="*" element={<Home />} />
              </Routes>
            </div>
            <Footer />
            {showUserMenu && <DesktopUserMenu />}
            {loading && <Loading />}
            <Notification />
            <MessageModal />
          </AppContainer>
        </DragHandleManager>
      </ThemeProvider>
    </DndContext>
  );
};

export default App;
