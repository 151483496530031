import React from "react";

const ModalContext = React.createContext();

const ModalContextProvider = ({ children }) => {
    const [messageModal, setMessageModal] = React.useState(null);
    const [formModal, setFormModal] = React.useState(null);
    const [notificationModal, setNotificationModal] = React.useState(null);

    return (
        <ModalContext.Provider
            value={{
                messageModal,
                setMessageModal,
                formModal,
                setFormModal,
                notificationModal,
                setNotificationModal,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
}

export { ModalContext, ModalContextProvider };