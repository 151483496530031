import React from "react";
import { UserContext } from "./UserContext";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firestore/cloud-configs";

const CatalogContext = React.createContext();

const CatalogContextProvider = ({ children }) => {
    const {user} = React.useContext(UserContext);
    const [pens, setPens] = React.useState([]);
    const [worlds, setWorlds] = React.useState([]);
    const [series, setSeries] = React.useState([]);
    const [books, setBooks] = React.useState([]);

    React.useEffect(() => {

        const subscribeCollectionWithCatch = (query, setData, collectionName) => {
            return onSnapshot(
              query,
              (snapshot) => {
                setData(
                  snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
                );
              },
              (error) => {
                console.error(
                  `Error in query for collection: ${collectionName}`,
                  error
                );
              }
            );
          };

        if(user){
            const unSubPens = subscribeCollectionWithCatch(
                collection(db, "users", user.uid, "pens"),
                setPens,
                "pens"
              );
              const unSubWorlds = subscribeCollectionWithCatch(
                collection(db, "users", user.uid, "worlds"),
                setWorlds,
                "worlds"
              );
              const unSubSeries = subscribeCollectionWithCatch(
                collection(db, "users", user.uid, "series"),
                setSeries,
                "series"
              );
              const unSubBooks = subscribeCollectionWithCatch(
                collection(db, "users", user.uid, "books"),
                setBooks,
                "books"
              );

                return () => {
                    unSubPens();
                    unSubWorlds();
                    unSubSeries();
                    unSubBooks();
                }
        }
    }, [user]);

    const contextValue = React.useMemo(
        () => ({
            pens,
            setPens,
            worlds,
            setWorlds,
            series,
            setSeries,
            books,
            setBooks,
        }),
        [pens, worlds, series, books]
    );

    return (
        <CatalogContext.Provider
            value={contextValue}
        >
            {children}
        </CatalogContext.Provider>
    );
}

export { CatalogContext, CatalogContextProvider };