import React, { useEffect } from "react";
import styled from "styled-components";
import { ConfirmButton, Jumbotron, JumbotronContainer, ScribiNavLink, TextInput } from "./Scribi-Components/ScribiComponents";
  import Icons from "./Icons/Icons";
  import { Button, Card, CardGroup, Nav } from "react-bootstrap";
import ScribiRestService from "./utilities/firestore/ScribiRestService";
import { Scribi } from "./utilities/api/store/store";

const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #555;
  width: 100%;
  max-width: 400px;
  padding-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  input.full-width {
    width: 100%;
  }
  label {
    text-align: justify;
  }
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 600px;
  z-index: 1;
`;

const HeadlineGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: white;
    line-height: 1.2;
    margin: 0;
  }

  p {
    font-size: 1.25rem;
    color: #e5e7eb;
    line-height: 1.5;
    margin: 0;
  }
`;

const CTAGroup = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const ButtonBase = styled(ScribiNavLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
`;

const PrimaryButton = styled(ButtonBase)`
  background-color: white;
  color: #0e4a73;
  border: none;

  &:hover {
    background-color: #e5e7eb;
  }
`;

const MailingList = () => {
  const { setLoading } = React.useContext(Scribi);
  const [email, setEmail] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [consent, setConsent] = React.useState(false);
  const [signedUp, setSignedUp] = React.useState(false);
  const [captchaToken, setCaptchaToken] = React.useState(null);
  const captchaRef = React.useRef(null);
  const [successMessage, setSuccessMessage] = React.useState("");

  const handleMailingListSignup = async () => {
    try {
      if (!captchaToken) {
        alert("Please complete the reCAPTCHA to proceed.");
        return;
      }
      if (!consent) {
        alert(
          "To comply with GDPR, we need your consent to send you emails. Please check the consent box below the form if you would like to sign up for the Scribi newsletter."
        );
        return;
      }
      if (!email) {
        alert(
          "Please enter your email address to sign up for the Scribi newsletter."
        );
        return;
      }
      setLoading(true);

      // Send token to server for verification
      const verified = await ScribiRestService.verifyRecaptcha(captchaToken);

      if (verified.success) {
        // Proceed with your form submission logic
        let res = await ScribiRestService.submitMailingListSignup(
          email,
          firstname,
          lastname
        );

        if (res.code === 200) {
          setLoading(false);
          setSuccessMessage(
            "You've successfully signed up for the Scribi newsletter! Remember to add news@scribi.app to your contacts or whitelist to ensure you receive our launch email!"
          );
        }
        if (res.code === 201) {
          setLoading(false);
          setSuccessMessage(
            "Hey, it look like you've already signed up for the Scribi newsletter. Awesome! Remember to add news@scribi.app to your contacts or whitelist to ensure you receive our launch email!"
          );
        }

        setSignedUp(true);
      } else {
        setLoading(false);
        alert(
          "Failed to verify reCAPTCHA. You have not been signed up to the newsletter."
        );
        setLastname("");
        setFirstname("");
        setEmail("");
      }
    } catch (error) {
      setLoading(false);
      alert(
        "Unfortunately, an error occurred during verification. You have not been signed up to the newsletter."
      );
      console.log(error);
    }
  };

  useEffect(() => {
    if (!window.grecaptcha) {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = () => {
        console.log("reCAPTCHA script loaded successfully.");
      };
      window.onSubmit = (e) => {
        setCaptchaToken(e);
      };
    } else {
      console.log("reCAPTCHA script already loaded.");
    }
  }, []);

  return  (
    <>
     <JumbotronContainer>
        <Jumbotron>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fsplash-logo.png?alt=media&token=f47bc348-5f10-4b8e-b4b8-6bbc801b135f"
            alt="Scribi Logo"
            className="logo"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fsplash-overlay.png?alt=media&token=1796ab9b-42ea-45f1-9448-022f8345c4ce"
            alt="Scribi Logo"
            className="overlay"
          />
          <ContentSection>
            <HeadlineGroup>
              <h1>everything. everywhere. all in one place.</h1>
              <p>
                There has never been an app for authors as feature rich or as
                flexible as this. Meet your new best friend.
              </p>
            </HeadlineGroup>
            {/* <CTAGroup>
              <PrimaryButton to="/download">
                Try Scribi Free for 14 Days
                <Icons.DownloadIcon />
              </PrimaryButton>
            </CTAGroup> */}
          </ContentSection>
        </Jumbotron>
      </JumbotronContainer>
    {!signedUp ? <FormBlock>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fsplash-title-graphic.png?alt=media&token=ec72c181-526e-423f-ba79-8fb95d1d1b7f"
        alt="Scribi Logo"
        className="logoText"
      />
      <label>
        Scribi hasn't launched yet, but launch day is just around the corner!
      </label>
      <label>
        Sign up for our mailing list, and you'll not only be the first to know
        when we launch, but you'll also get a special discount code for being an
        early adopter!
      </label>
      <TextInput
        className="full-width"  
        type="text"
        placeholder="First Name"
        value={firstname}
        onChange={(e) => setFirstname(e.target.value)}
      />
      <TextInput
      className="full-width" 
        type="text"
        placeholder="Last Name"
        value={lastname}
        onChange={(e) => setLastname(e.target.value)}
      />
      <TextInput
      className="full-width" 
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          justifyContent: "start",
        }}
      >
        <input
          type="checkbox"
          checked={consent}
          onChange={() => setConsent(!consent)}
        />
        <label>I consent to receiving emails from Scribi</label>
      </div>
      <div
        ref={captchaRef}
        className="g-recaptcha"
        data-sitekey="6Lc-gI8qAAAAAAPflXs1tNGBq0C66oV_IrQJC1Xd"
        data-callback="onSubmit"
      ></div>
      <ConfirmButton type="button" onClick={handleMailingListSignup}>
        Sign Up
      </ConfirmButton>
    </FormBlock> :  <FormBlock>
      <label>{successMessage}</label>
    </FormBlock>}
    </>   
  );
};

export default MailingList;
