import { db, auth } from "./cloud-configs";
import {
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  writeBatch,
  getDoc,
} from "firebase/firestore";
import sanitize from "./sanitize";
import { useContext } from "react";
import { Scribi } from "../api/store/store";

const checkAuthorization = async () => {
  try {
    const token = await auth.currentUser.getIdToken();
    const uid = auth.currentUser.uid;
    return { token, uid };
  } catch (error) {
    throw error;
  }
};

const checkOwnershipOrEditor = (uid, document) => {
  return (
    uid === document.ownerId ||
    (document.editors && document.editors.includes(uid))
  );
};

export const createScribiDocument = async (document) => {
  let { token, uid } = await checkAuthorization();
  if (!checkOwnershipOrEditor(uid, document)) {
    throw new Error("Unauthorized");
  }

  let sanitizedDocument = sanitize[document.doctype](document, uid);
  try {
    const docRef = doc(
      db,
      `users/${document.ownerId}/${document.doctype}`,
      document.id
    );
    await setDoc(docRef, sanitizedDocument);
    return { isCreated: true };
  } catch (error) {
    console.log(error);
    return { isCreated: false, error: error };
  }
};

export const batchCreateScribiDocuments = async (documents) => {
  try {
    let { token, uid } = await checkAuthorization();

    const batch = writeBatch(db);
    for (const document of documents) {
      if (!checkOwnershipOrEditor(uid, document)) {
        throw new Error("Unauthorized");
      }

      let sanitizedDocument = sanitize[document.doctype](document, uid);
      const docRef = doc(
        db,
        `users/${document.ownerId}/${document.doctype}`,
        document.id
      );
      batch.set(docRef, sanitizedDocument);
    }
    await batch.commit();
    return { isCreated: true };
  } catch (error) {
    return { isCreated: false };
  }
};

export const updateScribiDocument = async (document) => {
  let { token, uid } = await checkAuthorization();
  if (!checkOwnershipOrEditor(uid, document)) {
    throw new Error("Unauthorized");
  }

  let sanitizedDocument = sanitize[document.doctype](document, uid);
  try {
    const docRef = doc(
      db,
      `users/${document.ownerId}/${document.doctype}`,
      document.id
    );
    await updateDoc(docRef, sanitizedDocument);
    return { isUpdated: true };
  } catch (error) {
    return { isUpdated: false, error: error };
  }
};

export const updateScribiSprintTimer = async (document) => {
  let { token, uid } = await checkAuthorization();
  if (!checkOwnershipOrEditor(uid, document)) {
    throw new Error("Unauthorized");
  }

  let sanitizedDocument = sanitize[document.doctype](document, uid);
  try {
    const docRef = doc(db, `timers/${document.id}`, document.id);
    await updateDoc(docRef, sanitizedDocument);
    return { isUpdated: true };
  } catch (error) {
    return { isUpdated: false, error: error };
  }
};

export const createScribiSprintTimer = async (document) => {
  let { token, uid } = await checkAuthorization();
  if (!checkOwnershipOrEditor(uid, document)) {
    throw new Error("Unauthorized");
  }

  let sanitizedDocument = sanitize[document.doctype](document, uid);
  try {
    const docRef = doc(db, `timers`, document.id);
    await setDoc(docRef, sanitizedDocument);
    return { isCreated: true };
  } catch (error) {
    console.log(error);
    return { isCreated: false, error: error };
  }
};

export const updateScribiUser = async (document) => {
  let { token, uid } = await checkAuthorization();
  if (!checkOwnershipOrEditor(uid, document)) {
    throw new Error("Unauthorized");
  }

  let sanitizedDocument = sanitize[document.doctype](document, uid);
  try {
    const docRef = doc(db, `users`, sanitizedDocument.id);
    await updateDoc(docRef, sanitizedDocument);
    return { isUpdated: true };
  } catch (error) {
    return { isUpdated: false, error: error };
  }
};

export const deleteScribiDocument = async (document) => {
  let { token, uid } = await checkAuthorization();
  if (uid !== document.ownerId) {
    throw new Error("Unauthorized");
  }

  try {
    const docRef = doc(db, `users/${uid}/${document.doctype}`, document.id);
    await deleteDoc(docRef);
    return { isDeleted: true };
  } catch (error) {
    return { isDeleted: false, error: error };
  }
};

export const batchDeleteScribiDocuments = async (documents) => {
  try {
    let { token, uid } = await checkAuthorization();

    let batches = []; // Array to hold multiple batches
    let batch = writeBatch(db); // Initialize a new batch
    let opCount = 0;

    for (const document of documents) {
      // Skip documents not owned by the user
      if (uid !== document.ownerId) {
        continue;
      }

      if (opCount >= 500) {
        batches.push(batch); // Push the batch to the array and reset counters
        batch = writeBatch(db); // Initialize a new batch
        opCount = 0;
      }

      const docRef = doc(
        db,
        `users/${document.ownerId}/${document.doctype}`,
        document.id
      );

      batch.delete(docRef);
      opCount++;
    }

    // Push the last batch if it has operations
    if (opCount > 0) {
      batches.push(batch);
    }

    // Commit all batches
    const promises = batches.map((b) => b.commit());
    await Promise.all(promises);

    return { isDeleted: true };
  } catch (error) {
    console.error("Error in batchDeleteScribiDocuments: ", error);
    return { isDeleted: false };
  }
};

export const getScribiDocument = async (id) => {
  let { token, uid } = await checkAuthorization();
  try {
    const docRef = doc(db, `users/${uid}/documents`, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log(`Document with ID ${id} does not exist.`);
      return null;
    }
  } catch (error) {
    console.log("Error fetching document:", error);
    return null;
  }
};
