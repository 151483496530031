import React, { useState } from "react";
import styled from "styled-components";
import {
  Jumbotron,
  JumbotronContainer,
  ScribiNavLink,
} from "./Scribi-Components/ScribiComponents";
import Icons from "./Icons/Icons";
import { Button, Card, CardGroup, Nav } from "react-bootstrap";

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 600px;
  z-index: 1;
`;

const HeadlineGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: white;
    line-height: 1.2;
    margin: 0;
  }

  p {
    font-size: 1.25rem;
    color: #e5e7eb;
    line-height: 1.5;
    margin: 0;
  }
`;

const CTAGroup = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const ButtonBase = styled(ScribiNavLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
`;

const PrimaryButton = styled(ButtonBase)`
  background-color: white;
  color: #0e4a73;
  border: none;

  &:hover {
    background-color: #e5e7eb;
  }
`;

const SecondaryButton = styled(ButtonBase)`
  background-color: transparent;
  color: white;
  border: 2px solid white;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const FeatureDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  p,
  h3,
  h4 {
    color: #060606;
    max-width: 800px;
  }
`;

const Features = () => {
  const [feature, setFeature] = useState("plan");

  const content = {
    plan: () => {
      return (
        <>
           <Card.Title style={{ textAlign: "center" }}>
            Planning for Plotters, Pantsers, and Plotsers
          </Card.Title>
          <Card.Text>
            Scribi's suite of planning tools include every step of the creative process, from outlining, to worldbuilding, to settign up a manuscript scaffold to write to specific targets. You're free to take advantage of every tool available, or customize the modules you need for each project, whether you're struck with wild inspiration or carefully planning a year's worth of releases on a tight schedule.
          </Card.Text>
          <Card.Subtitle>
            Brainstorming
          </Card.Subtitle>
          <Card.Text>
            Brainstorming in Scribi isn't just for your next best-seller. You can brainstorm the marketing strategy for your pen name, spark ideas for your next big story world, work out the arc of your next book series or, yes, generate some ideas for the next novel. 
          </Card.Text>
          <Card.Text>
            You'll find a lot of the brainstorm questionnaires included in Scribi's resources, everything from consistent messaging for your pen name to character transformation arcs. The included brainstorms are designed to both prompt you and guide you through different aspects of both storytelling and career management. 
          </Card.Text>
          <Card.Text>
            But you don't have to stop there. You can make your own brainstorm questionnaires, or import brainstorms from other users. And if you come up with a brainstorm series that's been helpful in making sure you check all your boxes and cover all your bases, you can export and share it with a few clicks. Like most everything else in Scribi, you can turn a brainstorm into a template and reuse and refine it as you go.
          </Card.Text>
          <Card.Text>
            But once you've got your brainstorming done, who wants to do the work of remembering what you've answered and planned for and then go looking for it when you need it? Not only are brainstorms as searchable as everything else in Scribi, you'll find all your brainstorming material accessible from the workspace editors, so that everything you need is a click away when you need it, without having to leave your current task. 
          </Card.Text>
          <Card.Subtitle>
            Beat Sheet
          </Card.Subtitle>
          <Card.Text>
            
          </Card.Text>
        </>
      );
    },
    write: () => {
      return (
        <>
          <Card.Title style={{ textAlign: "center" }}></Card.Title>
          <Card.Text></Card.Text>
        </>
      );
    },
    build: () => {
      return (
        <>
          <Card.Title style={{ textAlign: "center" }}></Card.Title>
          <Card.Text></Card.Text>
        </>
      );
    },
    publish: () => {
      return (
        <>
          <Card.Title style={{ textAlign: "center" }}></Card.Title>
          <Card.Text></Card.Text>
        </>
      );
    },
    organize: () => {
      return (
        <>
          <Card.Title style={{ textAlign: "center" }}></Card.Title>
          <Card.Text></Card.Text>
        </>
      );
    },
    repeat: () => {
      return (
        <>
          <Card.Title style={{ textAlign: "center" }}></Card.Title>
          <Card.Text></Card.Text>
        </>
      );
    },
    share: () => {
      return (
        <>
          <Card.Title style={{ textAlign: "center" }}></Card.Title>
          <Card.Text></Card.Text>
        </>
      );
    },
  };
  return (
    <>
      <JumbotronContainer>
        <Jumbotron>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fsplash-logo.png?alt=media&token=f47bc348-5f10-4b8e-b4b8-6bbc801b135f"
            alt="Scribi Logo"
            className="logo"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/scribi-backend.appspot.com/o/public%2Fsplash-overlay.png?alt=media&token=1796ab9b-42ea-45f1-9448-022f8345c4ce"
            alt="Scribi Logo"
            className="overlay"
          />
          <ContentSection>
            <HeadlineGroup>
              <h1>everything. everywhere. all in one place.</h1>
              <p>
                There has never been an app for authors as feature rich or as
                flexible as this. Meet your new best friend.
              </p>
            </HeadlineGroup>
            <CTAGroup>
              <PrimaryButton to="/download">
                Try Scribi Free for 14 Days
                <Icons.DownloadIcon />
              </PrimaryButton>
            </CTAGroup>
          </ContentSection>
        </Jumbotron>
      </JumbotronContainer>
      <Card style={{ width: "100%", borderRadius: "0px" }}>
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey="#plan">
            <Nav.Item>
              <Nav.Link
                href="#"
                eventKey={"#plan"}
                onClick={() => setFeature("plan")}
              >
                Plan
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={"#write"}
                href="#"
                onClick={() => setFeature("write")}
              >
                Write
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={"#build"}
                href="#"
                onClick={() => setFeature("build")}
              >
                Build
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={"#publish"}
                href="#"
                onClick={() => setFeature("publish")}
              >
                Publish
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={"#organize"}
                href="#"
                onClick={() => setFeature("organize")}
              >
                Organize
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={"#repeat"}
                href="#"
                onClick={() => setFeature("repeat")}
              >
                Repeat
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={"#share"}
                href="#"
                onClick={() => setFeature("share")}
              >
                Share
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body
          style={{ width: "100%", maxWidth: "1000px", alignSelf: "center" }}
        >
          {content[feature]()}
        </Card.Body>
      </Card>
    </>
  );
};

export default Features;
